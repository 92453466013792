




    <!-- Navigation -->
    <nav id="navbar" class="navbar navbar-expand-lg fixed-top navbar-dark" aria-label="Main navigation">
      <div class="container">

          <!-- Image Logo -->
          <a class="navbar-brand logo-image" routerLink="/home"><img src="assets/images/Logos/Dark.svg" alt="alternative"></a> 

          <!-- Text Logo - Use this if you don't have a graphic logo -->
          <!-- <a class="navbar-brand logo-text" href="index.html">Elma</a> -->

          <button onClick="divFunction()" class="navbar-toggler  p-0 border-0 bg-primary " type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon  "></span>
          </button>

          <div class="navbar-collapse  offcanvas-collapse" id="navbarsExampleDefault">
              <!--<ul class="navbar-nav  ms-auto navbar-nav-scroll">-->
                <ul class="navbar-nav  ms-auto ">
                  <li class="nav-item item">
                      <a class="nav-link" aria-current="page" routerLink="/home" (click)="inicio()" >Home</a>
                  </li>
                  <li class="nav-item item2">
                      <a class="nav-link" aria-current="page" routerLink="/nosotros" (click)="inicio()" >About us</a>
                  </li>
                  <li class="nav-item dropdown">
                  
                     <span class="nav-link">Our Brands</span>
                     <!--<ul class="navbar-nav  ms-auto navbar-nav-scroll dropdown-content">-->
                        <ul class="navbar-nav  ms-auto  dropdown-content">
                        <li class="nav-item item3" style="border-bottom: 1px solid #003459;"> 
                            <a class="nav-link" aria-current="page" routerLink="/spirit">Spirits</a>
                        </li>
                        <li class="nav-item item4" style="border-bottom: 1px solid #003459;"> 
                            <a class="nav-link" aria-current="page" routerLink="/beers">Beers</a>
                        </li>
                        <li class="nav-item item5" style="border-bottom: 1px solid #003459;"> 
                            <a class="nav-link" aria-current="page" routerLink="/wines">Wines</a>
                        </li>
                        <li class="nav-item item6" style="border-bottom: 1px solid #003459;"> 
                            <a class="nav-link" aria-current="page" routerLink="/water">Water & Juices</a>
                        </li>
                        <li class="nav-item item7" style="border-bottom: 1px solid #003459;"> 
                            <a class="nav-link" aria-current="page" routerLink="/groceries">Groceries</a>
                        </li>
                     </ul>
            
                        
                  
                  </li>
                  <li class="nav-item item8">
                      <a class="nav-link" aria-current="page" href="#header" routerLink="/contacto">Contact Us</a>
                  </li>
              
              </ul>
              
          </div> <!-- end of navbar-collapse -->
      </div> <!-- end of container -->
  </nav> <!-- end of navbar -->
  <!-- end of navigation -->




<router-outlet></router-outlet>




