 <!-- About -->
 <div  class="counter">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="h2-heading"></h2>
                
            </div> <!-- end of col -->
        </div> <!-- end of row -->
        <div class="row">
            <div class="col-xl-6">
             
                                <h1>Contac Us</h1>
                                <p class="text2">Have a question? We're here to help. Send us a message andd we'll be in contact</p>

                                <input type="text" class="form-control inputcontact" placeholder="Full Name" >
                                <br>
                                <input type="email" class="form-control inputcontact" placeholder="Email">
                                <br>
                                <input type="text" class="form-control inputcontact" placeholder="Message">
                                <br>
                                <button class="btn btn-primary">Send</button>
                                <br>
                                <br>
                                <br>
                       
            </div> <!-- end of col -->
            <div class="col-xl-6 embed-responsive embed-responsive-16by9 mapa">
                <div class="mapouter"><div class="gmap_canvas"><iframe width="339" height="354" id="gmap_canvas" src="https://maps.google.com/maps?q=Humboldt%20Tower,%206th%20Floor%20East%2053rd%20Street,%20Urbanizaci%C3%B3n%20Marbella,%20Panam%C3%A1&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://123movies-to.org"></a><br><style>.mapouter{position:relative;text-align:right;height:354px;width:339px;}</style><a href="https://www.embedgooglemap.net">insert google map into website</a><style>.gmap_canvas {overflow:hidden;background:none!important;height:354px;width:339px;}</style></div></div>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of counter -->
<!-- end of about -->
