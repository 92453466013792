<!-- Header -->
<header id="header" class="header header2">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="texto3">
                    <h1 class="h1-large texto1"><br><br><br></h1>
                    <h1 class="h1-large">Our Spirits <br> </h1>
                    <p data-animation="fadeInLeft" data-delay=".6s" class="text">
                        We focus on giving life to brands, in order to distribute the best quality products.
                    </p>
                </div>
            </div> <!-- end of col -->
            <div class="col-lg-6">
                <div class="button-container">

                </div> <!-- end of button-container -->
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</header> <!-- end of header -->
<!-- end of header -->






<!--

<div class="carousel" id="test2">
    <input type="radio" name="item2" value="1" checked>
    <div>

    </div>

    <input type="radio" name="item2" value="2">
    <div>
       <h1>HOLA5</h1>
    </div>

</div>


-->

<!--
**************************************** -->

  <!-- Header -->
<div id="" class=" header3" >
    <div class="container">
      <div class="row" >
        <div class="carousel" id="test1">
                           <!--  *****carrucel -->
                           <!-- item ** -->
    <input type="radio" name="item1" value="1" checked>
     <div class="col-md-12" style="position:relative;" >
                               <!-- **contenido -->
                               <div class="row">
                                   <div  class="counter   col-md-12 fondodiv" id="">
                                       <svg viewBox="0 0 2580 1000" id="" class="">
                                   <!-- ***********************texto******************* -->
                                           <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609C"/>
                                           <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="textolsalvador" fill=" #00609C">
                                               Tequila
                                           </text>
                                           <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                               <tspan x="400" dy="90" >Tequila Ocho</tspan>
                                           </text>
                                           <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                               <xhtml:span class="copy-popover" style="">
                                                Unaged and honors the noble agaves of Arandas and the Camarena “Ranchos” through intense,
                                                lively and clean flavors Made with 100% Blue Agave. Tequila Ocho is a tequila that is made
                                                traditionally through a slow cooking process, Ocho accentuates the agave flavor and its flavor
                                                develops over time.
                                               </xhtml:span>
                                           </foreignObject>
                                          <!--  <text letter-spacing="1" x="400" y="500" font-family="Inter, sans-serif" font-weight="400" font-size="45"  fill=" #fff" xml:space="preserve">Tequila Milagro silver is a 100% blue   agave   tequila</text>
                                           <text x="400" y="550" font-family="Inter, sans-serif" font-weight="400" font-size="45 " fill=" #fff" xml:space="preserve">that is renowned for its crisp, fresh, agave taste and</text>
                                           <text x="400" y="600" font-family="Inter, sans-serif" font-weight="400" font-size="45" fill=" #fff" xml:space="preserve">world-class smoothness.</text> -->
                                   <!-- ********************************************* -->
                                   <!-----------------************imagen*********------------------>
                                           <defs >
                                           <pattern id="t1" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                           <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/TequilaOcho.svg" id="color"></image>
                                           </pattern>
                                       </defs>
                                       <rect width="2550" height="1550" fill="url(#t1)" class="fondo1"></rect>
                                   <!-------------------***********************------------------->
                                       </svg>
                                   </div>
                               </div>
                           <!--  fin contenido -->
      </div>
                           <!--  item*** -->




                            <!-- item ** -->
                            <input type="radio" name="item1" value="2">
                            <div class="col-md-12" style="position:relative;" >
                                <!-- **contenido -->
                                <div class="row">
                                    <div  class="counter   col-md-12 fondodiv" id="">
                                        <svg viewBox="0 0 2580 1000" id="" class="">
                                    <!-- ***********************texto******************* -->
                                            <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609C"/>
                                            <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="textolsalvador" fill=" #00609C">
                                                Tequila
                                            </text>
                                            <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                                <tspan x="400" dy="90" >Tequila Mi Campo</tspan>
                                            </text>
                                            <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                                <xhtml:span class="copy-popover" style="">
                                                  Mi Campo Reposado begins as 100% Blue Weber Agave, matured for seven years. It´s gently
                                                  squeeze the cooked pineapple, illuminating the agave plant’s natural honey characteristic and
                                                  quelling any trace of bitterness. Reposado is double distilled, then fermented in open-air tanks,
                                                  amid fragrant citrus trees, resting for three months in the same French oak barrels used for aging
                                                  Napa Valley Pinot Noir and Cabernet Sauvignon, imparting an incredibly smooth taste.
                                                </xhtml:span>
                                            </foreignObject>
                                           <!--  <text letter-spacing="1" x="400" y="500" font-family="Inter, sans-serif" font-weight="400" font-size="45"  fill=" #fff" xml:space="preserve">Tequila Milagro silver is a 100% blue   agave   tequila</text>
                                            <text x="400" y="550" font-family="Inter, sans-serif" font-weight="400" font-size="45 " fill=" #fff" xml:space="preserve">that is renowned for its crisp, fresh, agave taste and</text>
                                            <text x="400" y="600" font-family="Inter, sans-serif" font-weight="400" font-size="45" fill=" #fff" xml:space="preserve">world-class smoothness.</text> -->
                                    <!-- ********************************************* -->
                                    <!-----------------************imagen*********------------------>
                                            <defs >
                                            <pattern id="t2" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                              <image href="assets/images/Productos/TequilaMicampoReposado.png"  x="1300" y="0" height="1000" width="1350" preserveAspectRatio="xMidYMid meet" />
                                            </pattern>
                                        </defs>
                                        <rect width="2550" height="1550" fill="url(#t2)" class="fondo1"></rect>
                                    <!-------------------***********************------------------->
                                        </svg>
                                    </div>
                                </div>
                            <!--  fin contenido -->
                            </div>
                            <!--  item*** -->




                            <!-- item ** -->
                            <input type="radio" name="item1" value="3">
                            <div class="col-md-12" style="position:relative;" >
                                <!-- **contenido -->
                                <div class="row">
                                    <div  class="counter   col-md-12 fondodiv" id="">
                                        <svg viewBox="0 0 2580 1000" id="" class="">
                                    <!-- ***********************texto******************* -->
                                            <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609C"/>
                                            <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="textolsalvador" fill=" #00609C">
                                                Tequila
                                            </text>
                                            <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                                <tspan x="400" dy="90" >Tequila Mi Campo Blanco</tspan>
                                            </text>
                                            <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                                <xhtml:span class="copy-popover" style="">
                                                  Mi Campo Blanco begins as 100% Blue Weber Agave, matured for seven years. It´s gently squeeze
                                                  the cooked pineapple, illuminating the agave plant’s natural honey characteristic and quelling any
                                                  trace of bitterness. Blanco is double distilled, then fermented in open-air tanks, amid fragrant
                                                  citrus trees, before resting for up to one month in the same French oak barrels used for aging
                                                  Napa Valley Chardonnay, imparting an incredibly smooth taste.
                                                </xhtml:span>
                                            </foreignObject>
                                           <!--  <text letter-spacing="1" x="400" y="500" font-family="Inter, sans-serif" font-weight="400" font-size="45"  fill=" #fff" xml:space="preserve">Tequila Milagro silver is a 100% blue   agave   tequila</text>
                                            <text x="400" y="550" font-family="Inter, sans-serif" font-weight="400" font-size="45 " fill=" #fff" xml:space="preserve">that is renowned for its crisp, fresh, agave taste and</text>
                                            <text x="400" y="600" font-family="Inter, sans-serif" font-weight="400" font-size="45" fill=" #fff" xml:space="preserve">world-class smoothness.</text> -->
                                    <!-- ********************************************* -->
                                    <!-----------------************imagen*********------------------>
                                            <defs >
                                            <pattern id="t3" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                            <image x="1300" y="0" height="1000" width="1350" xlink:href="assets/images/Productos/TequilaBlancoReposado.png" id="color"></image>
                                            </pattern>
                                        </defs>
                                        <rect width="2550" height="1550" fill="url(#t3)" class="fondo1"></rect>
                                    <!-------------------***********************------------------->
                                        </svg>
                                    </div>
                                </div>
                            <!--  fin contenido -->
                            </div>
                            <!--  item*** -->



                           <!-- item ** -->
                            <input type="radio" name="item1" value="4">
                                <div class="col-md-12" style="position:relative;" >
                                    <!-- **contenido -->
                                    <div class="row">
                                        <div  class="counter   col-md-12 fondodiv" id="">
                                            <svg viewBox="0 0 2580 1000" id="" class="">
                                        <!-- ***********************texto******************* -->
                                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609C"/>
                                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="textolsalvador" fill=" #00609C">
                                                    Tequila
                                                </text>
                                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                                    <tspan x="400" dy="90" >Tequila Milagro Silver</tspan>
                                                </text>
                                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                                    <xhtml:span class="copy-popover" style="">
                                                        Tequila Milagro silver is a 100% blue agave tequila that is renowned for its crisp, fresh, agave taste and world-class smoothness.
                                                    </xhtml:span>
                                                </foreignObject>
                                               <!--  <text letter-spacing="1" x="400" y="500" font-family="Inter, sans-serif" font-weight="400" font-size="45"  fill=" #fff" xml:space="preserve">Tequila Milagro silver is a 100% blue   agave   tequila</text>
                                                <text x="400" y="550" font-family="Inter, sans-serif" font-weight="400" font-size="45 " fill=" #fff" xml:space="preserve">that is renowned for its crisp, fresh, agave taste and</text>
                                                <text x="400" y="600" font-family="Inter, sans-serif" font-weight="400" font-size="45" fill=" #fff" xml:space="preserve">world-class smoothness.</text> -->
                                        <!-- ********************************************* -->
                                        <!-----------------************imagen*********------------------>
                                                <defs >
                                                <pattern id="image1" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/TequilaMilagroSilver.png" id="color"></image>
                                                </pattern>
                                            </defs>
                                            <rect width="2550" height="1550" fill="url(#image1)" class="fondo1"></rect>
                                        <!-------------------***********************------------------->
                                            </svg>
                                        </div>
                                    </div>
                                <!--  fin contenido -->
                            </div>
                           <!--  item*** -->

                           <!-- item*** -->
                            <input type="radio" name="item1" value="5">
                            <div class="col-md-12" style="position:relative;" >

                                 <!-- **contenido2 -->
                                 <div class="row">
                                    <div  class="counter   col-md-12 fondodiv" id="">
                                        <svg viewBox="0 0 2580 1000" id="" class="">
                                    <!-- ***********************texto******************* -->
                                            <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609C"/>
                                            <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="textolsalvador" fill=" #00609C">
                                                Tequila
                                            </text>
                                            <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                                <tspan x="400" dy="90" >Tequila Milagro Reposado</tspan>
                                            </text>
                                            <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                                <xhtml:span class="copy-popover" style="">
                                                    Tequila Milagro Reposado is a 100% blue agave tequila which is rested in American oak barrels for up to 4 months for a robust flavor with front-and-center agave taste.
                                                </xhtml:span>
                                            </foreignObject>
                                    <!-- ********************************************* -->
                                    <!-----------------************imagen*********------------------>
                                            <defs >
                                            <pattern id="image2" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                            <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/TequilaMilagroReposado.png" id="color"></image>
                                            </pattern>
                                        </defs>
                                        <rect width="2550" height="1550" fill="url(#image2)" class="fondo1"></rect>
                                    <!-------------------***********************------------------->
                                        </svg>
                                    </div>
                                </div>
                            <!--  fin contenido -->

                            </div>
                            <!-- Fin item*** -->

                            <!-- item*** -->
                            <input type="radio" name="item1" value="6">
                            <div class="col-md-12" style="position:relative;" >

                                 <!-- **contenido2 -->
                                 <div class="row">
                                    <div  class="counter   col-md-12 fondodiv" id="">
                                        <svg viewBox="0 0 2580 1000" id="" class="">
                                    <!-- ***********************texto******************* -->
                                            <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609C"/>
                                            <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="textolsalvador" fill=" #00609C">
                                                Tequila
                                            </text>
                                            <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                                <tspan x="400" dy="90" >Tequila Espolón Blanco</tspan>
                                            </text>
                                            <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                                <xhtml:span class="copy-popover" style="">
                                                    Unaged, Blanco is the purest expression of Espolòn tequila. Created in the famed hills of Los Altos, is double distilled using column and pot stills to give it a perfectly smooth and balanced taste profile. Great on the rocks, this is the one you’ll love shaking into all the best tequila cocktails.
                                                </xhtml:span>
                                            </foreignObject>
                                    <!-- ********************************************* -->
                                    <!-----------------************imagen*********------------------>
                                            <defs >
                                            <pattern id="image1n" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                            <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/productosfinal/EspolonBlanco.png" id="color"></image>
                                            </pattern>
                                        </defs>
                                        <rect width="2550" height="1550" fill="url(#image1n)" class="fondo1"></rect>
                                    <!-------------------***********************------------------->
                                        </svg>
                                    </div>
                                </div>
                            <!--  fin contenido -->

                            </div>
                            <!-- Fin item*** -->

                            <!-- item*** -->
                            <input type="radio" name="item1" value="7">
                            <div class="col-md-12" style="position:relative;" >

                                 <!-- **contenido2 -->
                                 <div class="row">
                                    <div  class="counter   col-md-12 fondodiv" id="">
                                        <svg viewBox="0 0 2580 1000" id="" class="">
                                    <!-- ***********************texto******************* -->
                                            <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609C"/>
                                            <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="textolsalvador" fill=" #00609C">
                                                Tequila
                                            </text>
                                            <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                                <tspan x="400" dy="90" >Tequila Espolón Reposado</tspan>
                                            </text>
                                            <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                                <xhtml:span class="copy-popover" style="">
                                                    Tequila Reposado starts off life as Blanco. It’s then rested in lightly charred, new American oak barrels to create a more complex and well-rounded character uniquely Espolòn. This one begs to be shared in cocktails that deserve even greater tequila character.
                                                </xhtml:span>
                                            </foreignObject>
                                    <!-- ********************************************* -->
                                    <!-----------------************imagen*********------------------>
                                            <defs >
                                            <pattern id="image2n" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                            <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/productosfinal/EspolonReposado.png" id="color"></image>
                                            </pattern>
                                        </defs>
                                        <rect width="2550" height="1550" fill="url(#image2n)" class="fondo1"></rect>
                                    <!-------------------***********************------------------->
                                        </svg>
                                    </div>
                                </div>
                            <!--  fin contenido -->

                            </div>
                            <!-- Fin item*** -->

                        </div> <!-- end of container -->
                     </div>
    </div> <!-- end of header -->
</div>



<!-- ******************************** -->


 <!-- Header -->
<div id="" class=" header3" >
    <div class="container ">
          <div class="row">

            <div class="carousel" id="test2">

                <input type="radio" name="item2" value="1" checked>
                <div class="col-md-12" style="position:relative;" >
                     <!-- **contenido2 -->
                     <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #0095F4">
                                    Vodka
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Bols Vodka</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Bols Vodka has a superbly clean and pure palate, created through a process of charcoal filtering. The extraordinary level of purity makes Bols Vodka perfect for mixing in a huge variety of cocktails and mixers. A widely known and celebrated expression from the Bols range!
                                    </xhtml:span>
                                  </foreignObject>

                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image3" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/BolsVodka.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image3)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                <!--  fin contenido -->
                </div>

                <input type="radio" name="item2" value="2" >
                <div class="col-md-12" style="position:relative;" >
                     <!-- **contenido2 -->
                     <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #0095F4">
                                    Vodka
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Tito’s Vodka</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Distilled from corn, this handmade vodka offers forward and yet delicate aromas and flavours of roasted grain, black pepper and citrus, with hints of fruit and smoke. It's lightly creamy and smooth on the palate with a subtle sweetness on the warm and rounded finish.
                                    </xhtml:span>
                                  </foreignObject>

                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image3n" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/productosfinal/TitosVodka.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image3n)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                <!--  fin contenido -->
                </div>

            </div>

        </div> <!-- end of row -->


    </div> <!-- end of container -->
</div> <!-- end of header -->


  <!-- Header -->
   <div id="" class=" header3" >
    <div class="container "  >
          <div class="row" >
            <div class="carousel" id="test3">



              <input type="radio" name="item3" value="1" checked>
              <div class="col-md-12" style="position:relative;" >
                  <!-- **contenido2 -->
                  <div class="row">
                     <div  class="counter   col-md-12 fondodiv" id="">
                         <svg viewBox="0 0 2580 1000" id="" class="">



                     <!-- ***********************texto******************* -->
                             <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                             <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                              Whisky
                             </text>
                             <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                 <tspan x="400" dy="90" >Evan Williams Honey</tspan>
                             </text>

                             <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                              <xhtml:span class="copy-popover" style="">End a long day with Evan Williams Honey, a bourbon whiskey that&#39;s all flavor, no sting. Evan
                                Williams Honey features a nose of citrus and hints of honey with a long, slightly spicy finish. Evan
                                Williams Honey combines the smoothness of Straight Kentucky Bourbon with the sweetness of
                                real, natural honey. Enjoy Evan Williams Honey neat in a whiskey glass, on the rocks, in a signature
                                craft cocktail or mixed with iced tea. Evan Williams Honey is perfect for sharing with loved ones.</xhtml:span>
                            </foreignObject>
                     <!-- ********************************************* -->
                     <!-----------------************imagen*********------------------>
                             <defs >
                             <pattern id="w1" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                             <image x="1300" y="0" height="1020" width="1350" xlink:href="assets/images/Productos/EvanWilliamsHoney.svg" id="color"></image>
                             </pattern>
                         </defs>
                         <rect width="2550" height="1550" fill="url(#w1)" class="fondo1"></rect>
                     <!-------------------***********************------------------->
                         </svg>
                     </div>
                 </div>
             <!--  fin contenido -->
             </div>


             <input type="radio" name="item3" value="2">
              <div class="col-md-12" style="position:relative;" >
                  <!-- **contenido2 -->
                  <div class="row">
                     <div  class="counter   col-md-12 fondodiv" id="">
                         <svg viewBox="0 0 2580 1000" id="" class="">



                     <!-- ***********************texto******************* -->
                             <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                             <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                              Whisky
                             </text>
                             <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                 <tspan x="400" dy="90" >Evan Williams Fire</tspan>
                             </text>

                             <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                              <xhtml:span class="copy-popover" style="">This seriously good cinnamon infused Bourbon takes the term fire in your belly to a whole new
                                level. It’s got the hot cinnamon taste you love, with just the right amount of smooth Kentucky
                                Bourbon blended in. 70 Proof with a light copper color, Fire brings the heat and keeps it going all
                                night long. All Heat. No Burn. It’s finally ok to play with fire.</xhtml:span>
                            </foreignObject>
                     <!-- ********************************************* -->
                     <!-----------------************imagen*********------------------>
                             <defs >
                             <pattern id="w2" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                             <image x="1300" y="0" height="1020" width="1350" xlink:href="assets/images/Productos/EvanWilliamFire.svg" id="color"></image>
                             </pattern>
                         </defs>
                         <rect width="2550" height="1550" fill="url(#w2)" class="fondo1"></rect>
                     <!-------------------***********************------------------->
                         </svg>
                     </div>
                 </div>
             <!--  fin contenido -->
             </div>



                    <input type="radio" name="item3" value="3">
                    <div class="col-md-12" style="position:relative;" >
                        <!-- **contenido2 -->
                        <div class="row">
                           <div  class="counter   col-md-12 fondodiv" id="">
                               <svg viewBox="0 0 2580 1000" id="" class="">



                           <!-- ***********************texto******************* -->
                                   <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                                   <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                                    Whisky
                                   </text>
                                   <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                       <tspan x="400" dy="90" >Glenfiddich 12</tspan>
                                   </text>

                                   <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">Tullamore D.E.W. is the original triple blend Irish whiskey known the world over for its smooth and gentle complexity. Three spirits come together to give a balance of flavour like no other. Grain whiskey gives sweet flavours. Malt whiskey gives fruit flavours. And pot still whiskey gives spice flavours.</xhtml:span>
                                  </foreignObject>
                           <!-- ********************************************* -->
                           <!-----------------************imagen*********------------------>
                                   <defs >
                                   <pattern id="image4" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                   <image x="1300" y="0" height="1020" width="1350" xlink:href="assets/images/Productos/b3.png" id="color"></image>
                                   </pattern>
                               </defs>
                               <rect width="2550" height="1550" fill="url(#image4)" class="fondo1"></rect>
                           <!-------------------***********************------------------->
                               </svg>
                           </div>
                       </div>
                   <!--  fin contenido -->
                   </div>



                    <input type="radio" name="item3" value="4" >
                    <div class="col-md-12" style="position:relative;" >
                        <!-- **contenido2 -->
                        <div class="row">
                            <div  class="counter   col-md-12 fondodiv" id="">
                                <svg viewBox="0 0 2580 1000" id="" class="">
                            <!-- ***********************texto******************* -->
                                    <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                                    <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                                    Whisky
                                    </text>
                                    <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                        <tspan x="400" dy="90" >Glenfiddich 15</tspan>
                                    </text>
                                    <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                        <xhtml:span class="copy-popover" style="">
                                            Glenfiddich 15 Year Old is a single malt Scotch whiskey with intriguing aromas and an explosion of flavors. Glenfiddich's 15-year maturation process takes place in three different types of oak barrels: barrels from the maturation of Sherry wines, new oak barrels and American oak barrels.
                                        </xhtml:span>
                                    </foreignObject>


                            <!-- ********************************************* -->
                            <!-----------------************imagen*********------------------>
                                    <defs >
                                    <pattern id="image5" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                    <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/Glenfiddich15.png" id="color"></image>
                                    </pattern>
                                </defs>
                                <rect width="2550" height="1550" fill="url(#image5)" class="fondo1"></rect>
                            <!-------------------***********************------------------->
                                </svg>
                            </div>
                        </div>
                        <!--  fin contenido -->
                    </div>



                     <input type="radio" name="item3" value="5" >
                    <div class="col-md-12" style="position:relative;" >
                       <!-- **contenido2 -->
                       <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                                Whisky
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Monkey Shoulder</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Was originally created to make perfect whisky cocktails and has been getting in the mix ever since. It's a blend of malt whiskies from the Speyside (Highland) region of Scotland.
                                    </xhtml:span>
                                </foreignObject>

                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image6" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/MonkeyShoulder.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image6)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                        </div>
                    <!--  fin contenido -->
                    </div>

                    <input type="radio" name="item3" value="6" >
                    <div class="col-md-12" style="position:relative;" >
                       <!-- **contenido2 -->
                       <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                                Whisky
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Drambuie</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Made from honey and a secret herb and spice recipe, the legendary Scotch whiskey liqueur combines the essence created for Prince Charles of Scotland with fine aged Scottish malt. A blend of aged Scotch whisky, spices, herbs & heather honey.

                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image7" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/Drambuie.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image7)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                       </div>
                       <!--  fin contenido -->
                    </div>


                     <input type="radio" name="item3" value="7" >
                     <div class="col-md-12" style="position:relative;" >
                        <!-- **contenido2 -->
                        <div class="row">
                            <div  class="counter   col-md-12 fondodiv" id="">
                                <svg viewBox="0 0 2580 1000" id="" class="">
                            <!-- ***********************texto******************* -->
                                    <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                                    <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                                    Whisky
                                    </text>
                                    <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                        <tspan x="400" dy="90" >Grant’s Triple Wood</tspan>
                                    </text>
                                    <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                        <xhtml:span class="copy-popover" style="">
                                            Grant's Triple Wood is a whiskey aged in three different types of barrels that offers a delicious and silky flavor. Bright amber color, on the nose it gives notes of ripe pears and summer fruits; In the mouth there is a balance between vanilla and malt, with a long and sweet finish, with a little touch of smoke.
                                        </xhtml:span>
                                    </foreignObject>
                            <!-- ********************************************* -->
                            <!-----------------************imagen*********------------------>
                                    <defs >
                                    <pattern id="image8" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                    <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/GrantsTripleWood.png" id="color"></image>
                                    </pattern>
                                </defs>
                                <rect width="2550" height="1550" fill="url(#image8)" class="fondo1"></rect>
                            <!-------------------***********************------------------->
                                </svg>
                            </div>
                        </div>
                        <!--  fin contenido -->
                    </div>

                     <input type="radio" name="item3" value="8" >
                    <div class="col-md-12" style="position:relative;" >
                         <!-- **contenido2 -->
                         <div class="row">
                            <div  class="counter   col-md-12 fondodiv" id="">
                                <svg viewBox="0 0 2580 1000" id="" class="">
                            <!-- ***********************texto******************* -->
                                    <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                                    <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                                    Whisky
                                    </text>
                                    <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                        <tspan x="400" dy="90" >Tullamore D.E.W.</tspan>
                                    </text>
                                    <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                        <xhtml:span class="copy-popover" style="">
                                            Tullamore D.E.W. is the original triple blend Irish whiskey known the world over for its smooth and gentle complexity. Three spirits come together to give a balance of flavour like no other. Grain whiskey gives sweet flavours. Malt whiskey gives fruit flavours. And pot still whiskey gives spice flavours.
                                        </xhtml:span>
                                    </foreignObject>
                            <!-- ********************************************* -->
                            <!-----------------************imagen*********------------------>
                                    <defs >
                                    <pattern id="image9" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                    <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/TullamoreDEW.png" id="color"></image>
                                    </pattern>
                                </defs>
                                <rect width="2550" height="1550" fill="url(#image9)" class="fondo1"></rect>
                            <!-------------------***********************------------------->
                                </svg>
                            </div>
                        </div>
                        <!--  fin contenido -->

                    </div>

                    <input type="radio" name="item3" value="9" >
                    <div class="col-md-12" style="position:relative;" >
                         <!-- **contenido2 -->
                         <div class="row">
                            <div  class="counter   col-md-12 fondodiv" id="">
                                <svg viewBox="0 0 2580 1000" id="" class="">
                            <!-- ***********************texto******************* -->
                                    <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                                    <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                                    Whisky
                                    </text>
                                    <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                        <tspan x="400" dy="90" >Glenrothes</tspan>
                                    </text>
                                    <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                        <xhtml:span class="copy-popover" style="">
                                            The Glenrothes Manse Reserve is a whisky of unsurpassed quality. Crafted by Glenrothes Distillery, it is an unmistakable expression of the own style they have maintained over the years. This drink has been aged in ex-Bourbon barrels mainly, with the incorporation of a smaller portion from ex-Sherry barrels.
                                        </xhtml:span>
                                    </foreignObject>
                            <!-- ********************************************* -->
                            <!-----------------************imagen*********------------------>
                                    <defs >
                                    <pattern id="image4n" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                    <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/productosfinal/Glenrothes.png" id="color"></image>
                                    </pattern>
                                </defs>
                                <rect width="2550" height="1550" fill="url(#image4n)" class="fondo1"></rect>
                            <!-------------------***********************------------------->
                                </svg>
                            </div>
                        </div>
                        <!--  fin contenido -->

                    </div>

                    <input type="radio" name="item3" value="10" >
                    <div class="col-md-12" style="position:relative;" >
                         <!-- **contenido2 -->
                         <div class="row">
                            <div  class="counter   col-md-12 fondodiv" id="">
                                <svg viewBox="0 0 2580 1000" id="" class="">
                            <!-- ***********************texto******************* -->
                                    <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                                    <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                                    Whisky
                                    </text>
                                    <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                        <tspan x="400" dy="90" >Abasolo</tspan>
                                    </text>
                                    <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                        <xhtml:span class="copy-popover" style="">
                                            Is the first whisky made using exclusively 100% Peanut Creole corn, grown for hundreds of years by local farmers, this offers something unique that no other whisky has achieved in history. It is aged in an open cellar at Destilería y Bodega Abasolo, the first and only distillery dedicated solely to the production of whiskey in Mexico.
                                        </xhtml:span>
                                    </foreignObject>
                            <!-- ********************************************* -->
                            <!-----------------************imagen*********------------------>
                                    <defs >
                                    <pattern id="image5n" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                    <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/productosfinal/Abasolo.png" id="color"></image>
                                    </pattern>
                                </defs>
                                <rect width="2550" height="1550" fill="url(#image5n)" class="fondo1"></rect>
                            <!-------------------***********************------------------->
                                </svg>
                            </div>
                        </div>
                        <!--  fin contenido -->

                    </div>


                    <input type="radio" name="item3" value="11" >
                    <div class="col-md-12" style="position:relative;" >
                         <!-- **contenido2 -->
                         <div class="row">
                            <div  class="counter   col-md-12 fondodiv" id="">
                                <svg viewBox="0 0 2580 1000" id="" class="">
                            <!-- ***********************texto******************* -->
                                    <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                                    <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                                    Whisky
                                    </text>
                                    <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                        <tspan x="400" dy="90" >Cutty Sark</tspan>
                                    </text>
                                    <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                        <xhtml:span class="copy-popover" style="">
                                            The first and most famous of the Cutty Sark family, this blended scotch has been a worldwide whisky icon for the last 90 years. Cutty Sark is an easy-to-drink blended scotch whisky, which is light in colour and flavour  It was created for mixing with mates without overwhelming anyone’s taste buds.
                                        </xhtml:span>
                                    </foreignObject>
                            <!-- ********************************************* -->
                            <!-----------------************imagen*********------------------>
                                    <defs >
                                    <pattern id="image6n" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                    <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/newb/nb1.png" id="color"></image>
                                    </pattern>
                                </defs>
                                <rect width="2550" height="1550" fill="url(#image6n)" class="fondo1"></rect>
                            <!-------------------***********************------------------->
                                </svg>
                            </div>
                        </div>
                        <!--  fin contenido -->

                    </div>

                    <input type="radio" name="item3" value="12" >
                    <div class="col-md-12" style="position:relative;" >
                         <!-- **contenido2 -->
                         <div class="row">
                            <div  class="counter   col-md-12 fondodiv" id="">
                                <svg viewBox="0 0 2580 1000" id="" class="">
                            <!-- ***********************texto******************* -->
                                    <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                                    <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                                    Whisky
                                    </text>
                                    <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                        <tspan x="400" dy="90" >Evan Williams </tspan>
                                    </text>
                                    <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                        <xhtml:span class="copy-popover" style="">
                                            Kentucky Straight Bourbon is full of character and simply done right. Named after Evan Williams, who opened Kentucky’s First Distillery . The result is a Bourbon that’s smooth, rich, and easy to enjoy. With taste notes of oak, brown sugar and caramel
                                        </xhtml:span>
                                    </foreignObject>
                            <!-- ********************************************* -->
                            <!-----------------************imagen*********------------------>
                                    <defs >
                                    <pattern id="image7n" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                    <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/newb/nb2.png" id="color"></image>
                                    </pattern>
                                </defs>
                                <rect width="2550" height="1550" fill="url(#image7n)" class="fondo1"></rect>
                            <!-------------------***********************------------------->
                                </svg>
                            </div>
                        </div>
                        <!--  fin contenido -->

                    </div>





            </div>

        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of header -->


 <!-- Header -->
   <div id="" class=" header3" >
    <div class="container "  >
          <div class="row" >
            <div class="carousel" id="test4">





              <input type="radio" name="item4" value="1" checked>
              <div class="col-md-12" style="position:relative;" >

                   <!-- **contenido2 -->
                   <div class="row">
                      <div  class="counter   col-md-12 fondodiv" id="">
                          <svg viewBox="0 0 2580 1000" id="" class="">
                      <!-- ***********************texto******************* -->
                              <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609b"/>
                              <text  x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #00609b">
                                  Mezcal
                              </text>
                              <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                  <tspan x="400" dy="90" >Mezcal el Recuerdo Joven</tspan>
                              </text>
                              <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                  <xhtml:span class="copy-popover" style="">
                                    Without agave worm, for those looking for more traditional smoky notes, without extra complexity. Crystalline and shiny in color, with silver veins; Notes of fresh herbs, delicately smoked by the cooked agave. And balanced sweet and sour flavor, fresh citrus, herbs and delicate smoky effervescence.
                                  </xhtml:span>
                              </foreignObject>
                      <!-- ********************************************* -->
                      <!-----------------************imagen*********------------------>
                              <defs >
                              <pattern id="m9" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                              <image x="1300" y="0" height="1050" width="1350" xlink:href="assets/images/Productos/MEZCALELRECUERDOJOVEN.png" id="color"></image>
                              </pattern>
                          </defs>
                          <rect width="2550" height="1550" fill="url(#m9)" class="fondo1"></rect>
                      <!-------------------***********************------------------->
                          </svg>
                      </div>
                  </div>
                  <!--  fin contenido -->

              </div>





              <input type="radio" name="item4" value="2" checked>
              <div class="col-md-12" style="position:relative;" >

                   <!-- **contenido2 -->
                   <div class="row">
                      <div  class="counter col-md-12 fondodiv" id="">
                          <svg viewBox="0 0 2580 1000" id="" class="">
                      <!-- ***********************texto******************* -->
                              <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609b"/>
                              <text  x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #00609b">
                                  Mezcal
                              </text>
                              <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                  <tspan x="400" dy="90" >Mezcal el Recuerdo con abocado de Gusano</tspan>
                              </text>
                              <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                  <xhtml:span class="copy-popover" style="">
                                    Abocado con worm is for those who prefer the silky texture that the agave worm adds to Mezcal, bright yellow in color with golden sparkles; with soft herbal notes along with the exotic aroma of agave worm. With bittersweet notes and a subtle smoky effervescence.
                                  </xhtml:span>
                              </foreignObject>
                      <!-- ********************************************* -->
                      <!-----------------************imagen*********------------------>
                              <defs >
                              <pattern id="m10" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                              <image x="1300" y="0" height="1050" width="1350" xlink:href="assets/images/Productos/MEZCALELRECUERDOABOCADOCONGUSANO.png" id="color"></image>
                              </pattern>
                          </defs>
                          <rect width="2550" height="1550" fill="url(#m10)" class="fondo1"></rect>
                      <!-------------------***********************------------------->
                          </svg>
                      </div>
                  </div>
                  <!--  fin contenido -->

              </div>







              <input type="radio" name="item4" value="3" checked>
              <div class="col-md-12" style="position:relative;" >

                   <!-- **contenido2 -->
                   <div class="row">
                      <div  class="counter   col-md-12 fondodiv" id="">
                          <svg viewBox="0 0 2580 1000" id="" class="">
                      <!-- ***********************texto******************* -->
                              <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609b"/>
                              <text  x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #00609b">
                                  Mezcal
                              </text>
                              <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                  <tspan x="400" dy="90" >Ojo de Tigre Reposado</tspan>
                              </text>
                              <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                  <xhtml:span class="copy-popover" style="">
                                    The Ojo de Tigre is produced in Mexico according to 500 years old tradition. Espadín and Tobalá
                                    agaves are used for this special mezcal. After the plant is cooked, it is fermented and distilled in
                                    Copper Stills. Tasting notes: Color: gold. Nose: Agave notes, sweetish, soft. Taste: Slightly smoky,
                                    well balanced. Finish: Long lasting.
                                  </xhtml:span>
                              </foreignObject>
                      <!-- ********************************************* -->
                      <!-----------------************imagen*********------------------>
                              <defs >
                              <pattern id="m1" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                              <image x="1300" y="0" height="1050" width="1350" xlink:href="assets/images/Productos/OjodeTigreReposado.png" id="color"></image>
                              </pattern>
                          </defs>
                          <rect width="2550" height="1550" fill="url(#m1)" class="fondo1"></rect>
                      <!-------------------***********************------------------->
                          </svg>
                      </div>
                  </div>
                  <!--  fin contenido -->

              </div>





              <input type="radio" name="item4" value="4">
              <div class="col-md-12" style="position:relative;" >

                   <!-- **contenido2 -->
                   <div class="row">
                      <div  class="counter   col-md-12 fondodiv" id="">
                          <svg viewBox="0 0 2580 1000" id="" class="">
                      <!-- ***********************texto******************* -->
                              <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609b"/>
                              <text  x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #00609b">
                                  Mezcal
                              </text>
                              <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                  <tspan x="400" dy="90" >Ojo de Tigre Joven</tspan>
                              </text>
                              <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                  <xhtml:span class="copy-popover" style="">
                                    Ojo de Tigre Joven is produced with maguey Espadin (agave Angustifolia) from Oaxaca and
                                    maguey Tobala (agave Potatorum) from Puebla. The agaves are cooked in an underground pit
                                    over, milled with a tahona, and double distilled in a copper alembique still. It’s Crafted with two
                                    sustainably cultivated agaves, Espadin from Oaxaca and Tobala from Puebla, Ojo de Tigre Mezcal
                                    delivers a balanced flavor that welcomes first time mezcal drinkers to the world of mezcal.
                                  </xhtml:span>
                              </foreignObject>
                      <!-- ********************************************* -->
                      <!-----------------************imagen*********------------------>
                              <defs >
                              <pattern id="m2" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                              <image x="1300" y="0" height="1050" width="1350" xlink:href="assets/images/Productos/OjodeTigreJoven.png" id="color"></image>
                              </pattern>
                          </defs>
                          <rect width="2550" height="1550" fill="url(#m2)" class="fondo1"></rect>
                      <!-------------------***********************------------------->
                          </svg>
                      </div>
                  </div>
                  <!--  fin contenido -->

              </div>


                <input type="radio" name="item4" value="5">
                <div class="col-md-12" style="position:relative;" >

                     <!-- **contenido2 -->
                     <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609b"/>
                                <text  x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #00609b">
                                    Mezcal
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Montelobos Espadín</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Is an unaged joven mezcal, crafted from meticulously, cultivated organic agave Espadin, which is roasted underground and small batch distilled to achieve a perfect balance of sweetness and subtle smoke.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image10" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/MontelobosEspadín.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image10)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->

                </div>

                <input type="radio" name="item4" value="6" >
                <div class="col-md-12" style="position:relative;" >

                     <!-- **contenido2 -->
                     <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609b"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #00609b">
                                    Mezcal
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Montelobos Ensamble</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        This Montelobos expression offers an explosion of contrasting flavors such as pink pepper, chamomile and candied apple. It has a body and an acidity in the mouth that highlights smoke and fruit.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image11" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/MontelobosEnsamble.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image11)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->

                </div>

                <input type="radio" name="item4" value="7" >
                <div class="col-md-12" style="position:relative;" >

                     <!-- **contenido2 -->
                     <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609b"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #00609b">
                                    Mezcal
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Montelobos Tobalá</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Is an unaged joven mezcal, crafted from meticulously, cultivated organic agave, which is roasted underground and small batch distilled to achieve a perfect balance of sweetness and subtle smoke.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image12" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/MontelobosTobala.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image12)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->

                </div>


            </div>


        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of header -->



<!-- Header -->
   <div id="" class=" header3" >
    <div class="container "  >
          <div class="row" >

            <div class="carousel" id="test5">
                <input type="radio" name="item5" value="1" checked>
                <div class="col-md-12" style="position:relative;" >
                      <!-- **contenido2 -->
                      <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097f4"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #0097f4">
                                    Gin
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Hendrick's Gin</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Meet the delightfully peculiar Hendrick's Gin - a most unusual premium gin distilled in Scotland with curious, yet marvellous, infusions of cucumber & rose.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image13" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1050" width="1350" xlink:href="assets/images/Productos/b6.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image13)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->
                </div>

                <input type="radio" name="item5" value="2" >
                <div class="col-md-12" style="position:relative;" >

                    <!-- **contenido2 -->
                    <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097f4"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #0097f4">
                                    Gin
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Puerto de Indias Classic</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        It is a premium gin made in one of the dean distilleries of Andalusia, located in the Sevillian town of Carmona, through traditional production methods. Puerto de Indias Classic has a corpulent character and a transparent and crystalline appearance.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image14" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/PuertodeIndiasClassic.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image14)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->

                </div>

                <input type="radio" name="item5" value="3" >
                <div class="col-md-12" style="position:relative;" >

                     <!-- **contenido2 -->
                     <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097f4"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #0097f4">
                                    Gin
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Puerto de Indias Strawberry</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Is a special gin made from a maceration of strawberries from Lepe, Huelva, and the recipe of Puerto de Indias Classic, prepared by the Sevillian distillery Anís Los Hermanos.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                  <pattern id="image14s" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                    <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/PuertodeIndiasStrawberry.png" id="color"></image>
                                    </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image14s)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->


                </div>

                 <input type="radio" name="item5" value="4" >
                <div class="col-md-12" style="position:relative;" >
                   <!-- **contenido2 -->
                   <div class="row">
                    <div  class="counter   col-md-12 fondodiv" id="">
                        <svg viewBox="0 0 2580 1000" id="" class="">
                    <!-- ***********************texto******************* -->
                            <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097f4"/>
                            <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #0097f4">
                                Gin
                            </text>
                            <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                <tspan x="400" dy="90" >Bols Genever</tspan>
                            </text>
                            <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                <xhtml:span class="copy-popover" style="">
                                    Bols Genever is the original Dutch spirit, created from lightly distilled Dutch grains and a complex botanical mix. It is made according to the original 1820 Lucas Bols recipe which stood at the basis of the cocktail revolution in 19th century America.
                                </xhtml:span>
                            </foreignObject>
                    <!-- ********************************************* -->
                    <!-----------------************imagen*********------------------>
                            <defs >
                            <pattern id="image16" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                            <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/BolsGenever.png" id="color"></image>
                            </pattern>
                        </defs>
                        <rect width="2550" height="1550" fill="url(#image16)" class="fondo1"></rect>
                    <!-------------------***********************------------------->
                        </svg>
                    </div>
                   </div>
                   <!--  fin contenido -->
                </div>

                <input type="radio" name="item5" value="5" >
                <div class="col-md-12" style="position:relative;" >
                   <!-- **contenido2 -->
                   <div class="row">
                    <div  class="counter   col-md-12 fondodiv" id="">
                        <svg viewBox="0 0 2580 1000" id="" class="">
                    <!-- ***********************texto******************* -->
                            <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097f4"/>
                            <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #0097f4">
                                Gin
                            </text>
                            <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                <tspan x="400" dy="90" >Bulldog Gin</tspan>
                            </text>
                            <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                <xhtml:span class="copy-popover" style="">
                                    The iconic and multi award-winning BULLDOG Gin is a modern London Dry Gin with multiple layers of flavour and an unexpectedly smooth finish. Its 12 botanicals include the unique White Poppy from Turkey along with Dragon Eye and Lotus Leaf from China; adding a layer of distinction to its refreshing citrus notes.
                                </xhtml:span>
                            </foreignObject>
                    <!-- ********************************************* -->
                    <!-----------------************imagen*********------------------>
                    <defs >
                      <pattern id="image16f" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                      <image x="1300" y="0" height="950" width="1350" xlink:href="assets/images/Productos/BulldongGin.png" id="color"></image>
                      </pattern>
                  </defs>
                        <rect width="2550" height="1550" fill="url(#image16f)" class="fondo1"></rect>
                    <!-------------------***********************------------------->
                        </svg>
                    </div>
                   </div>
                   <!--  fin contenido -->
                </div>






                <input type="radio" name="item5" value="6" >
                <div class="col-md-12" style="position:relative;" >
                   <!-- **contenido2 -->
                   <div class="row">
                    <div  class="counter   col-md-12 fondodiv" id="">
                        <svg viewBox="0 0 2580 1000" id="" class="">
                    <!-- ***********************texto******************* -->
                            <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097f4"/>
                            <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #0097f4">
                                Gin
                            </text>
                            <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                <tspan x="400" dy="90" >MARTIN MILLERS</tspan>
                            </text>
                            <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                <xhtml:span class="copy-popover" style="">
                                  Martin Miller’s Original Gin. A modern classic dry gin based on the best traditions of English distillers but with our own twist to obtain the perfectly balanced gin. A gin that ‘tasted of gin’ in the words of Martin Miller. Nose Its alcohol level enhances a smooth flavour with lemon peels and pine notes; palate the slight liquorice sweetness enhance the citrus and spiced sensations; finish long and fresh feeling with lemon notes.                                </xhtml:span>
                            </foreignObject>
                    <!-- ********************************************* -->
                    <!-----------------************imagen*********------------------>
                    <defs >
                      <pattern id="image16f1" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                      <image x="1300" y="0" height="950" width="1350" xlink:href="assets/images/Productos/MARTINMILLERS.png" id="color"></image>
                      </pattern>
                  </defs>
                        <rect width="2550" height="1550" fill="url(#image16f1)" class="fondo1"></rect>
                    <!-------------------***********************------------------->
                        </svg>
                    </div>
                   </div>
                   <!--  fin contenido -->
                </div>



            </div>


        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of header -->


<!-- Header -->
   <div id="" class=" header3" >
    <div class="container "  >
          <div class="row" >

            <div class="carousel" id="test6">
                <input type="radio" name="item6" value="1" checked>
                <div class="col-md-12" style="position:relative;" >
                    <!-- **contenido2 -->
                   <div class="row">
                    <div  class="counter   col-md-12 fondodiv" id="">
                        <svg viewBox="0 0 2580 1000" id="" class="">
                    <!-- ***********************texto******************* -->
                            <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                            <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                                Digestives
                            </text>
                            <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                <tspan x="400" dy="90" >Jägermeister</tspan>
                            </text>
                            <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                <xhtml:span class="copy-popover" style="">
                                    Jägermeister is the most successful liqueur brand world-wide. 56 different herbs, blossoms, roots and fruits go to make up the inimitable and complex taste experience. You will discover well-balanced flavours of citrus, ginger, star anise and a gentle, pleasing herbal bitterness.
                                </xhtml:span>
                            </foreignObject>
                    <!-- ********************************************* -->
                    <!-----------------************imagen*********------------------>
                            <defs >
                            <pattern id="image17" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                            <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/Jagermeister.png" id="color"></image>
                            </pattern>
                        </defs>
                        <rect width="2550" height="1550" fill="url(#image17)" class="fondo1"></rect>
                    <!-------------------***********************------------------->
                        </svg>
                    </div>
                   </div>
                   <!--  fin contenido -->
                </div>

                <input type="radio" name="item6" value="2" >
                <div class="col-md-12" style="position:relative;" >

                     <!-- **contenido2 -->
                   <div class="row">
                    <div  class="counter   col-md-12 fondodiv" id="">
                        <svg viewBox="0 0 2580 1000" id="" class="">
                    <!-- ***********************texto******************* -->
                            <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                            <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                                Digestives
                            </text>
                            <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                <tspan x="400" dy="90" >Licor 43</tspan>
                            </text>
                            <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                <xhtml:span class="copy-popover" style="">
                                    With a vibrant gold color, soft and sweet flavor and with an inner light that gives it a unique personality and character, Licor 43 owes its aroma of citrus and orange blossom , which gives it a moving and suggestive nuance. This nuance is enhanced by the subtlety of a delicate touch of vanilla.
                                </xhtml:span>
                            </foreignObject>
                    <!-- ********************************************* -->
                    <!-----------------************imagen*********------------------>
                            <defs >
                            <pattern id="image18" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                            <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/Licor43.png" id="color"></image>
                            </pattern>
                        </defs>
                        <rect width="2550" height="1550" fill="url(#image18)" class="fondo1"></rect>
                    <!-------------------***********************------------------->
                        </svg>
                    </div>
                   </div>
                   <!--  fin contenido -->

                </div>

                 <input type="radio" name="item6" value="3" >
                <div class="col-md-12" style="position:relative;" >
                    <!-- **contenido2 -->
                   <div class="row">
                    <div  class="counter   col-md-12 fondodiv" id="">
                        <svg viewBox="0 0 2580 1000" id="" class="">
                    <!-- ***********************texto******************* -->
                            <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                            <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                                Digestives
                            </text>
                            <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                <tspan x="400" dy="90" >Jägermeister Manifest</tspan>
                            </text>
                            <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                <xhtml:span class="copy-popover" style="">
                                    Jägermeister Manifest is a premium herbal liqueur and line extension in the Jägermeister family. Jägermeister Manifest is distinct in taste, character, and processes but is closely tied to original Jägermeister as it shares the same base.
                                </xhtml:span>
                            </foreignObject>
                    <!-- ********************************************* -->
                    <!-----------------************imagen*********------------------>
                            <defs >
                            <pattern id="image19" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                            <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/JägermeisterManifest.png" id="color"></image>
                            </pattern>
                        </defs>
                        <rect width="2550" height="1550" fill="url(#image19)" class="fondo1"></rect>
                    <!-------------------***********************------------------->
                        </svg>
                    </div>
                   </div>
                   <!--  fin contenido -->
                </div>

                <input type="radio" name="item6" value="4" >
                <div class="col-md-12" style="position:relative;" >
                     <!-- **contenido2 -->
                     <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                                    Digestives
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Ancho Reyes Classic</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Ancho Reyes has a rich and complex warmth, followed by sweet spices and subtle acidity, and finishes with the pleasant, smoky heat of the ancho chile. Hints of spices, tamarind, plum, cacao, apple and almonds then subtle notes of fine herbs.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image20" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/AnchoReyesClassic.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image20)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                       </div>
                       <!--  fin contenido -->
                </div>

                 <input type="radio" name="item6" value="5" >
                <div class="col-md-12" style="position:relative;" >
                    <!-- **contenido2 -->
                    <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                                    Digestives
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Ancho Reyes Chile Verde Poblano</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Ancho Reyes Verde has a bright and herbal taste with notes of golden pineapple and a savory acidity finishing with the crisp, fresh heat of the poblano chile.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image21" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/AnchoReyesChileVerdePoblano.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image21)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->
                </div>

                <input type="radio" name="item6" value="6" >
                <div class="col-md-12" style="position:relative;" >
                    <!-- **contenido2 -->
                    <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                                    Digestives
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >GrandMarnier</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Grand Marnier is a French brand of liqueurs. The brand’s best-known product is Grand Marnier Cordon Rouge, an orange-flavored liqueur created in 1880 by Alexandre Marnier-Lapostolle. It is made from a blend of Cognac brandy, distilled essence of bitter orange, and sugar.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image8n" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/productosfinal/GrandMarnier.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image8n)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->
                </div>



            </div>


        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of header -->




 <!-- Header -->
 <div id="header" class="header3" >
    <div class="container "  >
          <div class="row" >
            <div class="carousel" id="test7">

                <input type="radio" name="item7" value="1" checked>
                <div class="col-md-12" style="position:relative;" >

                     <!-- **contenido2 -->
                     <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#0095F4">
                                    RUM
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Cihuatán</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        The Mayans are a source of inspiration, a Salvadoran rum made by hand in the heart of the Cihuatán Valley. Among its outstanding products is the Cihuatán “Indigo” rum, a kind of 8-year-old rum, in which the sleeping woman rests in the heart of each bottle. It is an ex-Bourbon American white oak, it has 35% volume of alcohol.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image243" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/newb/nb5.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image243)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->

                </div>








            </div>

        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of header -->


 <!-- Header APERITIVOS-->
 <div id="header" class="header3" >
  <div class="container "  >
        <div class="row" >
          <div class="carousel" id="test1">

              <input type="radio" name="item1" value="1" checked>
              <div class="col-md-12" style="position:relative;" >

                   <!-- **contenido2 -->
                   <div class="row">
                      <div  class="counter   col-md-12 fondodiv" id="">
                          <svg viewBox="0 0 2580 1000" id="" class="">
                      <!-- ***********************texto******************* -->
                              <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4"/>
                              <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#0095F4">
                                  APERITIVO
                              </text>
                              <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                  <tspan x="400" dy="90" >Starlino  </tspan>
                              </text>
                              <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                  <xhtml:span class="copy-popover" style="">
                                      Hotel Starlino in inspired by the architecture of our Distillery  Torino Distillati. Located just outside of Turin  the birthplace of the Aperitivos. Currently there are 3 types:   Starlino Rosé, Starlino Rosso Vermouth and Starlino Arancione Aperitivo. Standing out for its flavor and unique ingredients.
                                  </xhtml:span>
                              </foreignObject>
                      <!-- ********************************************* -->
                      <!-----------------************imagen*********------------------>
                              <defs >
                              <pattern id="image2435" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                              <image x="1300" y="0" height="1050" width="1350" xlink:href="assets/images/Productos/newb/nb80.png" id="color"></image>
                              </pattern>
                          </defs>
                          <rect width="2550" height="1550" fill="url(#image2435)" class="fondo1"></rect>
                      <!-------------------***********************------------------->
                          </svg>
                      </div>
                  </div>
                  <!--  fin contenido -->

              </div>

              <input type="radio" name="item1" value="2" checked>
              <div class="col-md-12" style="position:relative;" >

                   <!-- **contenido2 -->
                   <div class="row">
                      <div  class="counter   col-md-12 fondodiv" id="">
                          <svg viewBox="0 0 2580 1000" id="" class="">
                      <!-- ***********************texto******************* -->
                              <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4"/>
                              <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#0095F4">
                                  APERITIVO
                              </text>
                              <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                  <tspan x="400" dy="90" >CRUZ DE ALBA FINCA LOS HOYALES</tspan>
                              </text>
                              <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                  <xhtml:span class="copy-popover" style="">
                                    Signature wine, complex and elegant, with a modern cut and delicate structure. 100% Tempranillo variety. Of a marked bright red color with violet notes and a middle layer, it shows a fine and elegant nose of black fruit, followed by spicy notes and memories of the mountains that blend with an elegant and subtle wood. With a tasty palate, it shows a noble and structured character, with sensations of black fruit and roasted notes, finishing with a harmonious and ripe tannin, leaving us with a seductive wine sensation.
                                  </xhtml:span>
                              </foreignObject>
                      <!-- ********************************************* -->
                      <!-----------------************imagen*********------------------>
                              <defs >
                              <pattern id="image2436" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                              <image x="1300" y="0" height="950" width="1350" xlink:href="assets/images/Productos/.png" id="color"></image>
                              </pattern>
                          </defs>
                          <rect width="2550" height="1550" fill="url(#image2436)" class="fondo1"></rect>
                      <!-------------------***********************------------------->
                          </svg>
                      </div>
                  </div>
                  <!--  fin contenido -->

              </div>

              <input type="radio" name="item1" value="2" checked>
              <div class="col-md-12" style="position:relative;" >

                   <!-- **contenido2 -->
                   <div class="row">
                      <div  class="counter   col-md-12 fondodiv" id="">
                          <svg viewBox="0 0 2580 1000" id="" class="">
                      <!-- ***********************texto******************* -->
                              <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4"/>
                              <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#0095F4">
                                  APERITIVO
                              </text>
                              <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                  <tspan x="400" dy="90" >LUXARDO</tspan>
                              </text>
                              <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                  <xhtml:span class="copy-popover" style="">
                                    Luxardo Aperitivo is the result of a well balanced infusion of various herbs, roots and different kind of citrus fruits. Its mild alcoholic content makes it an ideal product to create low ABV drinks, perfect for aperitivo occasions. Mix it with Prosecco and soda for a perfect Spritz. Color: clear, pale orange crimson red; aroma: pink grapefruit and orange zest. Hint of spices; taste: slightly bitter, with flavours of zesty orange marmalade and pink grapefruit. Rhubarb and gentian notes.                                    </xhtml:span>
                              </foreignObject>
                      <!-- ********************************************* -->
                      <!-----------------************imagen*********------------------>
                              <defs >
                              <pattern id="image2436" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                              <image x="1300" y="0" height="950" width="1350" xlink:href="assets/images/Productos/LUXARDO.png" id="color"></image>
                              </pattern>
                          </defs>
                          <rect width="2550" height="1550" fill="url(#image2436)" class="fondo1"></rect>
                      <!-------------------***********************------------------->
                          </svg>
                      </div>
                  </div>
                  <!--  fin contenido -->

              </div>




          </div>
      </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of header -->




 <!-- Header   LIQUEOR (LICORES)-->
 <div id="" class=" header3" >
    <div class="container "  >
          <div class="row" >
            <div class="carousel" id="test9">


              <input type="radio" name="item9" value="1" checked>
              <div class="col-md-12" style="position:relative;" >
                   <!-- **contenido2 -->
                 <div class="row">
                  <div  class="counter   col-md-12 fondodiv" id="">
                      <svg viewBox="0 0 2580 1000" id="" class="">
                  <!-- ***********************texto******************* -->
                          <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B"/>
                          <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#00609B">
                              LIQUEUR
                          </text>
                          <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                              <tspan x="400" dy="90" >Gran Malo Tamarindo</tspan>
                          </text>
                          <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                              <xhtml:span class="copy-popover" style="">
                                The only and delicious Gran Malo elixir. The delicious expression of tropical grapefruit taste
                                tamarind hot with tequila. A different experience that will give you a nostalgic sensation. With
                                notes of wet earth, caramel, fruit, with a light touch of spicy tamarind with a touch of tequila, the
                                perfect combination for any occasion. This hot spirit can be enjoyed as a shot or mixed in your
                                favorite cocktail.
                              </xhtml:span>
                          </foreignObject>
                  <!-- ********************************************* -->
                  <!-----------------************imagen*********------------------>
                          <defs >
                          <pattern id="L1" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                          <image x="1300" y="0" height="1020" width="1350" xlink:href="assets/images/Productos/GranMalo.svg" id="color"></image>
                          </pattern>
                      </defs>
                      <rect width="2550" height="1550" fill="url(#L1)" class="fondo1"></rect>
                  <!-------------------***********************------------------->
                      </svg>
                  </div>
                  </div>
              <!--  fin contenido -->
              </div>

              <input type="radio" name="item9" value="2">
              <div class="col-md-12" style="position:relative;" >
                   <!-- **contenido2 -->
                 <div class="row">
                  <div  class="counter   col-md-12 fondodiv" id="">
                      <svg viewBox="0 0 2580 1000" id="" class="">
                  <!-- ***********************texto******************* -->
                          <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B"/>
                          <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#00609B">
                              LIQUEUR
                          </text>
                          <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                              <tspan x="400" dy="90" >Gran Malo Horchata</tspan>
                          </text>
                          <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                              <xhtml:span class="copy-popover" style="">
                                Gran Malo presents a new version, so you can degust its exquisite taste realized in base of Gran
                                Malo Blanco, Gluten Free, Horchata, Toast canella and water of natural rice, a different taste and a
                                unique for enjoy with family and friends.
                              </xhtml:span>
                          </foreignObject>
                  <!-- ********************************************* -->
                  <!-----------------************imagen*********------------------>
                          <defs >
                          <pattern id="L2" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                          <image x="1300" y="0" height="900" width="1350" xlink:href="assets/images/Productos/GranMaloHorchata.png" id="color"></image>
                          </pattern>
                      </defs>
                      <rect width="2550" height="1550" fill="url(#L2)" class="fondo1"></rect>
                  <!-------------------***********************------------------->
                      </svg>
                  </div>
                  </div>
              <!--  fin contenido -->
              </div>

                <input type="radio" name="item9" value="3">
                <div class="col-md-12" style="position:relative;" >
                     <!-- **contenido2 -->
                   <div class="row">
                    <div  class="counter   col-md-12 fondodiv" id="">
                        <svg viewBox="0 0 2580 1000" id="" class="">
                    <!-- ***********************texto******************* -->
                            <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B"/>
                            <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#00609B">
                                LIQUEUR
                            </text>
                            <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                <tspan x="400" dy="90" >Amaro de Angostura</tspan>
                            </text>
                            <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                <xhtml:span class="copy-popover" style="">
                                    Amaro di Angostura is derived from the bespoke process used in creating the House of Angostura’s iconic aromatic bitters. It is a deep amber colour, offering aromas of cinnamon, dark chocolate and unmistakeable Angostura aromatic bitters.
                                </xhtml:span>
                            </foreignObject>
                    <!-- ********************************************* -->
                    <!-----------------************imagen*********------------------>
                            <defs >
                            <pattern id="image320" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                            <image x="1300" y="0" height="1020" width="1350" xlink:href="assets/images/Productos/newb/nb90.png" id="color"></image>
                            </pattern>
                        </defs>
                        <rect width="2550" height="1550" fill="url(#image320)" class="fondo1"></rect>
                    <!-------------------***********************------------------->
                        </svg>
                    </div>
                    </div>
                <!--  fin contenido -->
                </div>

                <input type="radio" name="item9" value="4" >
                <div class="col-md-12" style="position:relative;" >
                    <!-- **contenido2 -->
                    <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#00609B">
                                    LIQUEUR
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Chinola</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Chinola liquor is an artisanal drink made from distillates and real passion fruit. Through a unique blend and proprietary formulation, a natural liqueur with unparalleled flavor and versatility is created. It can be consumed alone or mixed with any premium cocktail-based liquor.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image330" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/newb/nb100.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image330)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->
                </div>

                <input type="radio" name="item9" value="5" >
                <div class="col-md-12" style="position:relative;" >
                             <!-- **contenido2 -->
                             <div class="row">
                                <div  class="counter   col-md-12 fondodiv" id="">
                                    <svg viewBox="0 0 2580 1000" id="" class="">
                                <!-- ***********************texto******************* -->
                                        <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B"/>
                                        <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#00609B">
                                            LIQUEUR
                                        </text>
                                        <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                            <tspan x="400" dy="90" >Stambeco</tspan>
                                        </text>
                                        <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                            <xhtml:span class="copy-popover" style="">
                                                Stambeco is a product made with 35% Alcohol, it is perfect, smooth, fruity and delicious with ice, in a drink or with favorite cocktails such as Manhattan.
                                            </xhtml:span>
                                        </foreignObject>
                                <!-- ********************************************* -->
                                <!-----------------************imagen*********------------------>
                                        <defs >
                                        <pattern id="image340" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                        <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/newb/bn110.png" id="color"></image>
                                        </pattern>
                                    </defs>
                                    <rect width="2550" height="1550" fill="url(#image340)" class="fondo1"></rect>
                                <!-------------------***********************------------------->
                                    </svg>
                                </div>
                            </div>
                            <!--  fin contenido -->
                </div>

                <input type="radio" name="item9" value="6" >
                <div class="col-md-12" style="position:relative;" >
                             <!-- **contenido2 -->
                             <div class="row">
                                <div  class="counter   col-md-12 fondodiv" id="">
                                    <svg viewBox="0 0 2580 1000" id="" class="">
                                <!-- ***********************texto******************* -->
                                        <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B"/>
                                        <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#00609B">
                                            LIQUEUR
                                        </text>
                                        <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                            <tspan x="400" dy="90" >HPNOTIQ</tspan>
                                        </text>
                                        <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                            <xhtml:span class="copy-popover" style="">
                                              HPNOTIQ is a blend of exotic and natural fruit juices, premium vodka and a touch of cognac and can be served mixed with premium spirits such as cognac, rum, vodka or champagne. Hpnotiq remains the second largest fruit-based liquor. Hpnotiq is the perfect storm of captivating flavor, striking blue color, and elegant packaging. The tasting notes of Hpnotiq are aromas of tropical fruits, citrus and mango. Light, acidic and very acidic entry into the mouth; The palate is refreshing and intensely juicy.                                            </xhtml:span>
                                        </foreignObject>
                                <!-- ********************************************* -->
                                <!-----------------************imagen*********------------------>
                                        <defs >
                                        <pattern id="image341" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                        <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/HPNOTIQ.png" id="color"></image>
                                        </pattern>
                                    </defs>
                                    <rect width="2550" height="1550" fill="url(#image341)" class="fondo1"></rect>
                                <!-------------------***********************------------------->
                                    </svg>
                                </div>
                            </div>
                            <!--  fin contenido -->
                </div>

                <input type="radio" name="item9" value="7" >
                <div class="col-md-12" style="position:relative;" >
                             <!-- **contenido2 -->
                             <div class="row">
                                <div  class="counter   col-md-12 fondodiv" id="">
                                    <svg viewBox="0 0 2580 1000" id="" class="">
                                <!-- ***********************texto******************* -->
                                        <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B"/>
                                        <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#00609B">
                                            LIQUEUR
                                        </text>
                                        <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                            <tspan x="400" dy="90" >PAMA</tspan>
                                        </text>
                                        <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                            <xhtml:span class="copy-popover" style="">
                                              Pama is a liquor made from 100% natural pomegranate juice, premium vodka and a touch of tequila. Its intense ruby ​​color and bittersweet pomegranate tart flavor constitute the essence of this surprising liqueur, very versatile and which shows its characteristic notes both alone and in mixed drinks or cocktails. An innovation to incorporate into the cupboard of good drinks.
                                            </xhtml:span>
                                        </foreignObject>
                                <!-- ********************************************* -->
                                <!-----------------************imagen*********------------------>
                                        <defs >
                                        <pattern id="image342" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                        <image x="1300" y="0" height="1050" width="1350" xlink:href="assets/images/Productos/PAMA.png" id="color"></image>
                                        </pattern>
                                    </defs>
                                    <rect width="2550" height="1550" fill="url(#image342)" class="fondo1"></rect>
                                <!-------------------***********************------------------->
                                    </svg>
                                </div>
                            </div>
                            <!--  fin contenido -->
                </div>

                <input type="radio" name="item9" value="7" >
                <div class="col-md-12" style="position:relative;" >
                             <!-- **contenido2 -->
                             <div class="row">
                                <div  class="counter   col-md-12 fondodiv" id="">
                                    <svg viewBox="0 0 2580 1000" id="" class="">
                                <!-- ***********************texto******************* -->
                                        <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B"/>
                                        <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#00609B">
                                            LIQUEUR
                                        </text>
                                        <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                            <tspan x="400" dy="90" >DOMAINE DE CANTON</tspan>
                                        </text>
                                        <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                            <xhtml:span class="copy-popover" style="">
                                              Domaine de Canton is the world's first ultra-premium ginger liqueur. It is inspired by the French tradition, in the Indochinese colonial era, of drinking sweet and fresh elixirs fortified with fine spirits and cognac. The best ingredients in the world are used for its preparation, without preservatives or stabilizers.
                                            </xhtml:span>
                                        </foreignObject>
                                <!-- ********************************************* -->
                                <!-----------------************imagen*********------------------>
                                        <defs >
                                        <pattern id="image343" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                        <image x="1300" y="0" height="1050" width="1350" xlink:href="assets/images/Productos/DOMAINEDECANTON.png" id="color"></image>
                                        </pattern>
                                    </defs>
                                    <rect width="2550" height="1550" fill="url(#image343)" class="fondo1"></rect>
                                <!-------------------***********************------------------->
                                    </svg>
                                </div>
                            </div>
                            <!--  fin contenido -->
                </div>

                <input type="radio" name="item9" value="7" >
                <div class="col-md-12" style="position:relative;" >
                             <!-- **contenido2 -->
                             <div class="row">
                                <div  class="counter   col-md-12 fondodiv" id="">
                                    <svg viewBox="0 0 2580 1000" id="" class="">
                                <!-- ***********************texto******************* -->
                                        <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B"/>
                                        <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#00609B">
                                            LIQUEUR
                                        </text>
                                        <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                            <tspan x="400" dy="90" >DU BOUCHETT BLUE CURACAO</tspan>
                                        </text>
                                        <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                            <xhtml:span class="copy-popover" style="">
                                              Du Bouchett Blue Curacao has a slightly bitter orange flavor, perfect in cocktails. Try the unique taste of Laraha oranges from the island of Curacao. Explore all the ways to enjoy this spirit type and share your favorite recipes with us! Du Bouchett is a full line of liqueurs and cordials recognized for its great flavor, quality, variety and mixability. Made from the finest 100% natural fruits and flavorings available, Du Bouchett products offer consumers and on-premise operators the opportunity to economically take advantage of the resurgence of traditional cocktails and liqueurs with a high quality product.



                                            </xhtml:span>
                                        </foreignObject>
                                <!-- ********************************************* -->
                                <!-----------------************imagen*********------------------>
                                        <defs >
                                        <pattern id="image344" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                        <image x="1300" y="0" height="900" width="1350" xlink:href="assets/images/Productos/DUBOUCHETTBLUECURACAO.webp" id="color"></image>
                                        </pattern>
                                    </defs>
                                    <rect width="2550" height="1550" fill="url(#image344)" class="fondo1"></rect>
                                <!-------------------***********************------------------->
                                    </svg>
                                </div>
                            </div>
                            <!--  fin contenido -->
                </div>

                <input type="radio" name="item9" value="7" >
                <div class="col-md-12" style="position:relative;" >
                             <!-- **contenido2 -->
                             <div class="row">
                                <div  class="counter   col-md-12 fondodiv" id="">
                                    <svg viewBox="0 0 2580 1000" id="" class="">
                                <!-- ***********************texto******************* -->
                                        <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B"/>
                                        <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#00609B">
                                            LIQUEUR
                                        </text>
                                        <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                            <tspan x="400" dy="90" >DU BOUCHETT TRIPLE SEC</tspan>
                                        </text>
                                        <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                            <xhtml:span class="copy-popover" style="">
                                              Triple Sec offers a light and balanced orange flavor, perfect in cocktails. Du Bouchett is a full line of liqueurs and cordials recognized for its great flavor, quality, variety and mixability. Made from the finest 100% natural fruits and flavorings available, Du Bouchett products offer consumers and on-premises operators the opportunity to economically take advantage of the resurgence of traditional cocktails and liqueurs with a high-quality product. Du Bouchett is a versatile spirit which is easily enjoyed for a special occasion or can be simply sipped as an everyday cocktail.


                                            </xhtml:span>
                                        </foreignObject>
                                <!-- ********************************************* -->
                                <!-----------------************imagen*********------------------>
                                        <defs >
                                        <pattern id="image345" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                        <image x="1300" y="0" height="900" width="1350" xlink:href="assets/images/Productos/DUBOUCHETTTRIPLESEC.png" id="color"></image>
                                        </pattern>
                                    </defs>
                                    <rect width="2550" height="1550" fill="url(#image345)" class="fondo1"></rect>
                                <!-------------------***********************------------------->
                                    </svg>
                                </div>
                            </div>
                            <!--  fin contenido -->
                </div>

                <input type="radio" name="item9" value="7" >
                <div class="col-md-12" style="position:relative;" >
                             <!-- **contenido2 -->
                             <div class="row">
                                <div  class="counter   col-md-12 fondodiv" id="">
                                    <svg viewBox="0 0 2580 1000" id="" class="">
                                <!-- ***********************texto******************* -->
                                        <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B"/>
                                        <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#00609B">
                                            LIQUEUR
                                        </text>
                                        <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                            <tspan x="400" dy="90" >DU BOUCHETT CREME DE CACAO</tspan>
                                        </text>
                                        <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                            <xhtml:span class="copy-popover" style="">
                                              Du Bouchett is a full line of liqueurs and cordials recognized for its great flavor, quality, variety and mixability. Made from the finest 100% natural fruits and flavorings available, Du Bouchett products offer consumers and on-premise operators the opportunity to economically take advantage of the resurgence of traditional cocktails and liqueurs with a high quality product. Du Bouchett Creme De Cacao White has a sweet white chocolate flavor, scented with a hint of vanilla. This is a versatile spirit which is easily enjoyed for a special occasion or can be simply sipped as an everyday cocktail.



                                            </xhtml:span>
                                        </foreignObject>
                                <!-- ********************************************* -->
                                <!-----------------************imagen*********------------------>
                                        <defs >
                                        <pattern id="image346" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                        <image x="1300" y="0" height="900" width="1350" xlink:href="assets/images/Productos/DUBOUCHETTCREMEDECACAO.webp" id="color"></image>
                                        </pattern>
                                    </defs>
                                    <rect width="2550" height="1550" fill="url(#image346)" class="fondo1"></rect>
                                <!-------------------***********************------------------->
                                    </svg>
                                </div>
                            </div>
                            <!--  fin contenido -->
                </div>

                <input type="radio" name="item9" value="7" >
                <div class="col-md-12" style="position:relative;" >
                             <!-- **contenido2 -->
                             <div class="row">
                                <div  class="counter   col-md-12 fondodiv" id="">
                                    <svg viewBox="0 0 2580 1000" id="" class="">
                                <!-- ***********************texto******************* -->
                                        <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B"/>
                                        <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#00609B">
                                            LIQUEUR
                                        </text>
                                        <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                            <tspan x="400" dy="90" >LICOR NIXTA</tspan>
                                        </text>
                                        <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                            <xhtml:span class="copy-popover" style="">
                                              Nixta Licor de Elote is a Mexican corn liqueur made from a base of tender, ancestral cacahuazintle maize, grown in the high valleys and foothills of the Nevado de Toluca volcano. A straw-like coloured liquid reveals aromas of cornbread and toasted corn. To taste, there's a full dose sweet corn perfectly balanced with toasted notes adding vanilla and caramel like backdrop. A great base for a twist on the Spritz.


                                            </xhtml:span>
                                        </foreignObject>
                                <!-- ********************************************* -->
                                <!-----------------************imagen*********------------------>
                                        <defs >
                                        <pattern id="image347" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                        <image x="1300" y="0" height="900" width="1350" xlink:href="assets/images/Productos/NIXTA.png" id="color"></image>
                                        </pattern>
                                    </defs>
                                    <rect width="2550" height="1550" fill="url(#image347)" class="fondo1"></rect>
                                <!-------------------***********************------------------->
                                    </svg>
                                </div>
                            </div>
                            <!--  fin contenido -->
                </div>

                <input type="radio" name="item9" value="7" >
                <div class="col-md-12" style="position:relative;" >
                             <!-- **contenido2 -->
                             <div class="row">
                                <div  class="counter   col-md-12 fondodiv" id="">
                                    <svg viewBox="0 0 2580 1000" id="" class="">
                                <!-- ***********************texto******************* -->
                                        <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B"/>
                                        <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#00609B">
                                            LIQUEUR
                                        </text>
                                        <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                            <tspan x="400" dy="90" >LICOR LUXARDO MARASCHINO</tspan>
                                        </text>
                                        <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                            <xhtml:span class="copy-popover" style="">
                                              Maraschino Luxardo is a delicious liqueur made from cherries that are extracted from the same plantation of the Luxardo house; became an essential ingredient in cocktails internationally, along with other products that the Luxardo family began to produce shortly after the foundation, such as the “Sangue Morlacco” Cherry Liqueur, the Original Maraschino Cherries, the Limoncello and many others.

                                            </xhtml:span>
                                        </foreignObject>
                                <!-- ********************************************* -->
                                <!-----------------************imagen*********------------------>
                                        <defs >
                                        <pattern id="image349" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                        <image x="1300" y="0" height="900" width="1350" xlink:href="assets/images/Productos/LUXARDO.png" id="color"></image>
                                        </pattern>
                                    </defs>
                                    <rect width="2550" height="1550" fill="url(#image349)" class="fondo1"></rect>
                                <!-------------------***********************------------------->
                                    </svg>
                                </div>
                            </div>
                            <!--  fin contenido -->
                </div>

            </div>
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of header -->


<div id="" class=" header3" >
  <div class="container "  >
        <div class="row" >
          <div class="carousel" id="test3">



            <input type="radio" name="item11" value="1" checked>
            <div class="col-md-12" style="position:relative;" >
                <!-- **contenido2 -->
                <div class="row">
                   <div  class="counter   col-md-12 fondodiv" id="">
                       <svg viewBox="0 0 2580 1000" id="" class="">



                   <!-- ***********************texto******************* -->
                           <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                           <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                            BITTER
                           </text>
                           <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                               <tspan x="400" dy="90" >BITTER LUXARDO ROSSO</tspan>
                           </text>

                           <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                            <xhtml:span class="copy-popover" style="">Luxardo Bitter is obtained from a separate infusion of bitter herbs, aromatic plants and citrus fruits in water and alcohol. Such infusions are then blended together according a Luxardo Family original recipe before being filtered and bottled. Bright red in color, herbal and citrusy aromas meet distinctive gentian and wormwood flavors, which distinguish Luxardo Bitter. Ideal for many classic cocktails such as Americano or Negroni, it can also be enjoyed neat or over ice as an aperitivo. Try it as well hard shaken and double strained in a coupette, you’ll have the perfect “Shakerato”.
                            </xhtml:span>
                          </foreignObject>
                   <!-- ********************************************* -->
                   <!-----------------************imagen*********------------------>
                           <defs >
                           <pattern id="b1" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                           <image x="1300" y="0" height="920" width="1350" xlink:href="assets/images/Productos/LUXARDOROSSOBITTER.png" id="color"></image>
                           </pattern>
                       </defs>
                       <rect width="2550" height="1550" fill="url(#b1)" class="fondo1"></rect>
                   <!-------------------***********************------------------->
                       </svg>
                   </div>
               </div>
           <!--  fin contenido -->
           </div>

           <input type="radio" name="item11" value="2">
            <div class="col-md-12" style="position:relative;" >
                <!-- **contenido2 -->
                <div class="row">
                   <div  class="counter   col-md-12 fondodiv" id="">
                       <svg viewBox="0 0 2580 1000" id="" class="">



                   <!-- ***********************texto******************* -->
                           <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                           <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                            BITTER
                           </text>
                           <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                               <tspan x="400" dy="90" >BITTER LUXARDO BIANCO</tspan>
                           </text>

                           <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                            <xhtml:span class="copy-popover" style="">
                              Bitter Bianco shares the same infusions of bitter herbs, aromatic plants and citrus fruits with the “red” Bitter. However, before the blending process, most of these infusions are distilled, which makes the product colorless and slightly modifies the flavor profile. At the end of the process, an infusion of Roman wormwood is added to enhance the bitter flavor, giving the product an ivory color. Bitter Bianco is perfect for making a “twist” of classic cocktails such as the Negroni that becomes a Negroni Bianco, a Paloma into a Paloma Bianco, etc. But it can also be enjoyed alone or with ice as a classic aperitif.

                            </xhtml:span>
                          </foreignObject>
                   <!-- ********************************************* -->
                   <!-----------------************imagen*********------------------>
                           <defs >
                           <pattern id="b2" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                           <image x="1300" y="0" height="920" width="1350" xlink:href="assets/images/Productos/LUXARDOBIANCOBITTER.png" id="color"></image>
                           </pattern>
                       </defs>
                       <rect width="2550" height="1550" fill="url(#b2)" class="fondo1"></rect>
                   <!-------------------***********************------------------->
                       </svg>
                   </div>
               </div>
           <!--  fin contenido -->
           </div>

           <input type="radio" name="item11" value="3">
            <div class="col-md-12" style="position:relative;" >
                <!-- **contenido2 -->
                <div class="row">
                   <div  class="counter   col-md-12 fondodiv" id="">
                       <svg viewBox="0 0 2580 1000" id="" class="">



                   <!-- ***********************texto******************* -->
                           <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                           <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                            BITTER
                           </text>
                           <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                               <tspan x="400" dy="90" >SUZE BITTER</tspan>
                           </text>

                           <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                            <xhtml:span class="copy-popover" style="">
The iconic French bitter with a distinctive bittersweet taste, a bright yellow color and a unique personality for a refreshing and flavorful drinking experience. Color: bright yellow color; nose: intense and harmonious, with fruity aromas and vegetal notes; palate: bitterness and sweetness followed by a fresh taste of gentian roots, a fruity taste of orange and candied fruits. Suze is perfect to light a cocktail up for a flavorful drinking experience.
                            </xhtml:span>
                          </foreignObject>
                   <!-- ********************************************* -->
                   <!-----------------************imagen*********------------------>
                           <defs >
                           <pattern id="b3" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                           <image x="1300" y="0" height="920" width="1350" xlink:href="assets/images/Productos/SUZE.png" id="color"></image>
                           </pattern>
                       </defs>
                       <rect width="2550" height="1550" fill="url(#b3)" class="fondo1"></rect>
                   <!-------------------***********************------------------->
                       </svg>
                   </div>
               </div>
           <!--  fin contenido -->
           </div>

           <input type="radio" name="item11" value="3">
           <div class="col-md-12" style="position:relative;" >
               <!-- **contenido2 -->
               <div class="row">
                  <div  class="counter   col-md-12 fondodiv" id="">
                      <svg viewBox="0 0 2580 1000" id="" class="">



                  <!-- ***********************texto******************* -->
                          <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                          <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                           BITTER
                          </text>
                          <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                              <tspan x="400" dy="90" >CRUZ DE ALBA RESERVA FUENTELUN</tspan>
                          </text>

                          <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                           <xhtml:span class="copy-popover" style="">
                            A riverside reserve in which the fruit notes and the toasted, chocolatey and sweet spice sensations left by the oak wood are perfectly integrated. Soft, silky, polished and full of elegance although not devoid of drive and liveliness. Deep red in color; aroma of black fruits, chocolate and light toast; highlighting the volume wrapped in a fresh and clean atmosphere that leaves a pleasant wine sensation that is finished off with polished and soft tannin.
                           </xhtml:span>
                         </foreignObject>
                  <!-- ********************************************* -->
                  <!-----------------************imagen*********------------------>
                          <defs >
                          <pattern id="b4" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                          <image x="1300" y="0" height="920" width="1350" xlink:href="assets/images/Productos/CRUZ DE ALBA RESERVA FUENTELUN.png" id="color"></image>
                          </pattern>
                      </defs>
                      <rect width="2550" height="1550" fill="url(#b4)" class="fondo1"></rect>
                  <!-------------------***********************------------------->
                      </svg>
                  </div>
              </div>
          <!--  fin contenido -->
          </div>

          <input type="radio" name="item11" value="3">
           <div class="col-md-12" style="position:relative;" >
               <!-- **contenido2 -->
               <div class="row">
                  <div  class="counter   col-md-12 fondodiv" id="">
                      <svg viewBox="0 0 2580 1000" id="" class="">



                  <!-- ***********************texto******************* -->
                          <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                          <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                           BITTER
                          </text>
                          <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                              <tspan x="400" dy="90" >CRUZ DE ALBA CRIANZA</tspan>
                          </text>

                          <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                           <xhtml:span class="copy-popover" style="">
                            Crianza is the essence of all the terroirs of Cruz de Alba. A reading of the potential of the vineyard, the evolution of the vintage and the specific virtues of each plot. Sum of grapes (therefore, characters), with a result that we try to synthesize the power of Tempranillo from Ribera with the vibrant nerve of the vines with balanced soil. Fluid, structured, stimulating. The effort required by biodynamic cultivation seems to obtain the reward of a fresh, slender, well-integrated temperament.
                           </xhtml:span>
                         </foreignObject>
                  <!-- ********************************************* -->
                  <!-----------------************imagen*********------------------>
                          <defs >
                          <pattern id="b5" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                          <image x="1300" y="0" height="920" width="1350" xlink:href="assets/images/Productos/CRUZ DE ALBA CRIANZA.png" id="color"></image>
                          </pattern>
                      </defs>
                      <rect width="2550" height="1550" fill="url(#b5)" class="fondo1"></rect>
                  <!-------------------***********************------------------->
                      </svg>
                  </div>
              </div>
          <!--  fin contenido -->
          </div>

          <input type="radio" name="item11" value="3">
           <div class="col-md-12" style="position:relative;" >
               <!-- **contenido2 -->
               <div class="row">
                  <div  class="counter   col-md-12 fondodiv" id="">
                      <svg viewBox="0 0 2580 1000" id="" class="">



                  <!-- ***********************texto******************* -->
                          <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                          <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                           BITTER
                          </text>
                          <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                              <tspan x="400" dy="90" >RAMON BILBAO EDICION LIMITADA</tspan>
                          </text>

                          <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                           <xhtml:span class="copy-popover" style="">
                            It is 100% Tempranillo, with soul and fruitiness. Intense ruby ​​red color with garnet reflections. Clean and bright. With the presence of tears when turning the glass. On the nose: clean and frank, with high intensity. Predominance of black fruit (currant, plum, blackberry), licorice, herbal aromas and wood. The passage through the barrel provides noble wood, spicy spices and cocoa. In the mouth: long, intense and with balanced acidity. Presence of round and fine tannin. In retronasal it is reminiscent of the aromas recorded in the nose.
                           </xhtml:span>
                         </foreignObject>
                  <!-- ********************************************* -->
                  <!-----------------************imagen*********------------------>
                          <defs >
                          <pattern id="b6" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                          <image x="1300" y="0" height="920" width="1350" xlink:href="assets/images/Productos/RAMON BILVAO EDICION LIMITADA.png" id="color"></image>
                          </pattern>
                      </defs>
                      <rect width="2550" height="1550" fill="url(#b6)" class="fondo1"></rect>
                  <!-------------------***********************------------------->
                      </svg>
                  </div>
              </div>
          <!--  fin contenido -->
          </div>

          </div>

      </div> <!-- end of row -->
  </div> <!-- end of container -->
</div>


<div id="" class=" header3" >
  <div class="container "  >
        <div class="row" >
          <div class="carousel" id="test3">



            <input type="radio" name="item11" value="1" checked>
            <div class="col-md-12" style="position:relative;" >
                <!-- **contenido2 -->
                <div class="row">
                   <div  class="counter   col-md-12 fondodiv" id="">
                       <svg viewBox="0 0 2580 1000" id="" class="">



                   <!-- ***********************texto******************* -->
                           <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                           <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill=" #003459">
                            SPARKLING LIQUOR
                           </text>
                           <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                               <tspan x="400" dy="90" >NUVO LICOR ESPUMOSO</tspan>
                           </text>

                           <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                            <xhtml:span class="copy-popover" style="">
                              This liqueur has a delicate touch that comes from the sparkling white wine, made with Chardonnay and Pinor Noir, with an exquisite touch blend of ultra-premium French vodka, which is distilled three times. In addition, it is melted with an original and patented blend of fruit nectars that provides it with a luxurious flavor. This liqueur was developed by Rafael Yakoby, who is the creator of the Hpnotiq brand. This brand is famous for its luxuriously developed drinks and its exclusivity. A delicacy consumed by people of high social standing, such as singers and actors, the Liqueur entered the United States market in 2007 and quickly gained its fame, as Hpnotiq. The Nuvo L'Esprit De Paris Sparkling Liqueur is bottled in a premium bottle that stands out on any occasion, making this liqueur a perfect gift and an ideal drink for whatever you need it for.
                            </xhtml:span>
                          </foreignObject>
                   <!-- ********************************************* -->
                   <!-----------------************imagen*********------------------>
                           <defs >
                           <pattern id="z1" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                           <image x="1300" y="0" height="920" width="1350" xlink:href="assets/images/Productos/NUVO LICOR ESPUMOSO.png" id="color"></image>
                           </pattern>
                       </defs>
                       <rect width="2550" height="1550" fill="url(#z1)" class="fondo1"></rect>
                   <!-------------------***********************------------------->
                       </svg>
                   </div>
               </div>
           <!--  fin contenido -->
           </div>

          </div>

      </div> <!-- end of row -->
  </div> <!-- end of container -->
</div>
