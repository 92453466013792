  
    <!-- Header -->
    <header id="header" class="header header2">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h1 class="h1-large"><br></h1>
                    <h3>About Us</h3>
                    <br>
                     <p data-animation="fadeInLeft" data-delay=".6s" class="text">Founded in 2009 our company is focused on the development of world leading brands in the Central American region. We take advantage of a globalized market and create long term relationships with our business partners and customers to whom we offer new products that suit their lifestyles In the past years, our group has been able to gain market share for its brands and more importantly has gained recognition for the work done in such a short period of time.</p>
                     <br>
                      <p data-animation="fadeInLeft" data-delay=".6s" class="text">We operate as Premier Distributions in El Salvador, Honduras, Guatemala, Costa Rica and Belize. This centralization allows us to expand and become a fast-growing brand value development company that strives to provide high quality products to the most demanding consumers.</p>
                </div> <!-- end of col -->
                <div class="col-lg-6">
                    <div class="button-container">
                       <img src="assets/images/about/1.png" class="img-fluid">
                    </div> <!-- end of button-container -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </header> <!-- end of header -->
    <!-- end of header -->

        <!-- Header -->
    <div id="header" class="header header3">
        <div class="container">
              <div class="row">
                 <div class="col-lg-7">
                    <div class="button-container">
                       <img src="assets/images/about/2.png" class="img-fluid">
                    </div> <!-- end of button-container -->
                </div> <!-- end of col -->
                <div class="col-lg-5">
                    <h1 class="h1-large texto1"><br><br><br></h1>
                    <h3>Our Mission</h3>
                    <br>
                     <p data-animation="fadeInLeft" data-delay=".6s" class="text">Provide a select variety of high-end products to the most demanding consumers in Central America, by building healthy business relationship both with our partners and retailers who serve the end consumer. Satisfied consumers have proven to be our most valuable asset.

                    </p>
               
                </div> <!-- end of col -->
               
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of header -->
    <!-- end of header -->

        <!-- Header -->
    <div id="header" class="header">
        <div class="container">
              <div class="row">
                <div class="col-lg-6">
                    <h1 class="h1-large texto1"><br>  <br>  <br></h1>
                    <h3>Our Vision</h3>
                    <br>
                     <p data-animation="fadeInLeft" data-delay=".6s" class="text">Become a fast-growing brand building organization that exceeds the expectations of our customers, by providing them with a range of high-quality products and personalized attention. We will strive to maintain a close relationship with our direct customers and partners in order to reach more potential markets.</p>
                     <br>
                     
                </div> <!-- end of col -->
                <div class="col-lg-6">
                    <div class="button-container">
                       <img src="assets/images/about/3.png" class="img-fluid">
                    </div> <!-- end of button-container -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of header -->
    <!-- end of header -->





  




