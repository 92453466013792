<!-- Header -->
<header id="header" class="header">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 letras1">
                <h1 class="h1-large">Inspired by <br> excellence</h1>
                 <p data-animation="fadeInLeft" data-delay=".6s" class="text">A single place to distribute, create and discover brands<br>
                 that tells a story</p>
            </div> <!-- end of col -->
            <div class="col-lg-5">
                <div class="button-container">

                </div> <!-- end of button-container -->
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</header> <!-- end of header -->
<!-- end of header -->

  <!-- About -->
  <div  class="counter">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="h2-heading"></h2>

            </div> <!-- end of col -->
        </div> <!-- end of row -->
        <div class="row">
            <div class="col-xl-6">
                <ul class="list-unstyled">
                    <li class="d-flex">

                        <div class="flex-grow-1">
                            <h1>Ours 12 years of <br>
                            experience</h1>
                            <div class="">
                                <p class="text2">We are specialized in marketing and expert in the distribution, focused on the development of leading brands worldwide.</p></div>
                        </div>
                    </li>

                </ul>
            </div> <!-- end of col -->
            <div class="col-xl-6">

                <!-- Counter -->
                <div class="counter-container">
                    <div class="counter-cell container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-4">
                                         <div  class=""><img src="assets/images/Stats/usuario.svg"> </div>
                                    </div>
                                    <div class="col-md-8 xol-md-6">
                                        <!--<div data-purecounter-start="0" data-purecounter-end="100" data-purecounter-duration="3" class="purecounter">1 </div>-->
                                          <div  class=""><h1>100+</h1> </div>
                                          <div class="counter-info">Collaborators</div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div> <!-- end of counter-cell -->
                    <div class="counter-cell">
                         <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-4" >
                                         <div  class=""><img src="assets/images/Stats/brands.svg"> </div>
                                    </div>

                                    <div class="col-md-8 col-md-6" >
                                        <!--<div data-purecounter-start="0" data-purecounter-end="100" data-purecounter-duration="3" class="purecounter">1 </div>-->
                                          <div  class=""><h1>30+</h1> </div>
                                          <div class="counter-info">Brands</div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div> <!-- end of counter-cell -->
                    <div class="counter-cell">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-1"></div>

                                    <div class="col-md-3" >
                                         <div  class=""><img src="assets/images/Stats/clientes.svg"> </div>
                                    </div>

                                    <div class="col-md-3 col-md-6">
                                        <!--<div data-purecounter-start="0" data-purecounter-end="100" data-purecounter-duration="3" class="purecounter">1 </div>-->
                                          <div  class=""><h1 style="font-size: 30px;">4,000+</h1> </div>
                                          <div class="counter-info">Clients</div>

                                    </div>
                                  <div class="col-md-2"></div>
                                </div>
                            </div>
                        </div>
                    </div> <!-- end of counter-cell -->
                    <div class="counter-cell">
                       <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-4" >
                                         <div  class=""><img src="assets/images/Stats/countries.svg"> </div>
                                    </div>

                                    <div class="col-md-8 col-md-6" >
                                        <!--<div data-purecounter-start="0" data-purecounter-end="100" data-purecounter-duration="3" class="purecounter">1 </div>-->
                                          <div  class=""><h1>6+</h1> </div>
                                          <div class="counter-info">Countries</div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> <!-- end of counter-cell -->
                </div> <!-- end of counter-container -->
                <!-- end of counter -->

            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of counter -->
<!-- end of about -->


 <!-- Team -->
 <div class="cards-2 bg-gray">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="h2-heading">Our Brands</h2>
                <p class="">We focus on giving life to brands, in order to distribute the best quality products. these are some of the brands we manage:</p>
                <br>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
        <div class="row">


            <div class="col-lg-12">
                <div class="row">
                <!-- Card -->
                    <div class="col-md-1 ">
                    </div>
                    <div class="col-md-2">
                      <div class="carta">
                     <img  src="assets/images/Brands/polar.png" class="responsive">
                     </div>
                 </div>
                    <div class="col-md-2 ">
                        <div class="carta">
                       <img src="assets/images/Brands/Acqua_Panna.svg" class="responsive">
                       </div>
                   </div>
                   <div class="col-md-2 ">
                        <div class="carta">
                       <img src="assets/images/Brands/Belaire.svg" class="responsive cartaimg2">
                       </div>
                   </div>
                   <div class="col-md-2 ">
                        <div class="carta">
                       <img src="assets/images/Brands/Bols.svg" class="img-responsive cartaimg responsive">
                       </div>
                   </div>
                   <div class="col-md-2 ">
                        <div class="carta">
                       <img src="assets/images/Brands/Bulldog.svg" class="img-responsive cartaimg responsive">
                       </div>
                   </div>

                    <div class="col-md-1 ">
                    </div>




               </div>
               <br>
               <div class="row">
                <!-- Card -->
                    <div class="col-md-1 ">
                    </div>
                    <div class="col-md-2 ">
                        <div class="carta">
                       <img src="assets/images/Brands/Espolon.svg" class="responsive cartaimg2">
                       </div>
                   </div>
                   <div class="col-md-2 ">
                        <div class="carta">
                       <img src="assets/images/Brands/Glenfiddich.svg" class="responsive ">
                       </div>
                   </div>
                   <div class="col-md-2 ">
                        <div class="carta">
                       <img src="assets/images/Brands/GrandMarnier.svg" class="img-responsive cartaimg responsive">
                       </div>
                   </div>
                   <div class="col-md-2 ">
                        <div class="carta">
                       <img src="assets/images/Brands/Grolsch.svg" class="img-responsive cartaimg responsive">
                       </div>
                   </div>
                   <div class="col-md-2 ">
                        <div class="carta">

                       <img src="assets/images/Brands/Jagermeister.svg" class="img-responsive cartaimg responsive">
                       </div>
                   </div>
                    <div class="col-md-1 ">
                    </div>




               </div>

                <br>
               <div class="row">
                <!-- Card -->
                    <div class="col-md-1 ">
                    </div>
                    <div class="col-md-2 ">
                        <div class="carta">
                       <img src="assets/images/Brands/Licor_43.svg" class="responsive ">
                       </div>
                   </div>
                   <div class="col-md-2 ">
                        <div class="carta">
                       <img src="assets/images/Brands/Margherita.svg" class="responsive cartaimg2">
                       </div>
                   </div>
                   <div class="col-md-2 ">
                        <div class="carta">
                       <img src="assets/images/Brands/Montelobos.svg" class="img-responsive cartaimg responsive">
                       </div>
                   </div>
                   <div class="col-md-2 ">
                        <div class="carta">
                       <img src="assets/images/Brands/Ocean.svg" class="img-responsive cartaimg2 responsive">
                       </div>
                   </div>
                   <div class="col-md-2 ">
                        <div class="carta">

                       <img src="assets/images/Brands/Peroni.svg" class="img-responsive cartaimg responsive">
                       </div>
                   </div>
                    <div class="col-md-1 ">
                    </div>




               </div>
               <br>
               <div class="row">
                <!-- Card -->
                    <div class="col-md-1 ">
                    </div>
                    <div class="col-md-2 ">
                        <div class="carta">
                       <img src="assets/images/Brands/Perrier.svg" class="responsive ">
                       </div>
                   </div>
                   <div class="col-md-2 ">
                        <div class="carta">
                       <img src="assets/images/Brands/Puerto_de_Indias.svg" class="responsive cartaimg2">
                       </div>
                   </div>
                   <div class="col-md-2 ">
                        <div class="carta">
                       <img src="assets/images/Brands/SPellegrino.svg" class="img-responsive cartaimg responsive">
                       </div>
                   </div>
                   <div class="col-md-2 ">
                        <div class="carta">
                       <img src="assets/images/Brands/Titos.svg" class="img-responsive cartaimg responsive">
                       </div>
                   </div>
                   <div class="col-md-2 ">
                        <div class="carta">

                       <img src="assets/images/Brands/Torresella.svg" class="img-responsive cartaimg responsive">
                       </div>
                   </div>
                    <div class="col-md-1 ">
                    </div>














               </div>

                <!-- end of card -->

            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of cards-2 -->
<!-- end of team -->


  <!-- About -->
  <div  class="counter header2" style="  position: relative;">
    <div class="container ">
        <div class="row">
            <div class="col-lg-12" align="center">
                <h2 class="h2-heading">Our Companies</h2>
                <p>
                    Throught the years we have created powerfull global corporate brands also we represent companies with real values, they are buit around great consumer brands
                </p>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->

</div> <!-- end of counter -->


<div  class="counter   header2 col-md-12 fondodiv" id="fluid">
    <svg viewBox="0 0 2580 1500" id="" class="">
    <!-----------------************fondo*********------------------>
        <defs >
          <pattern id="image4" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
          <image x="-2050" y="-60" height="1750" width="6680" xlink:href="assets/images/mapa/MAP-01.svg" id="color"></image>
          </pattern>
       </defs>
       <rect width="2550" height="1550" fill="url(#image4)" class="fondo1"></rect>
            <!--<circle id="top" cx="1400" cy="700" r="1500" stroke="green" fill="url(#image4)"></circle>-->
<!-------------------***********************------------------->
<!-----------------************EL SALVADOR*********------------------>

        <filter id="this_image" x="0%" y="0%" width="100%" height="100%" class="oculto">
          <feImage xlink:href="assets/images/mapa/MAP-03.svg" ></feImage>
        </filter>
        <circle onmouseover="bigImg(this)" onmouseout="normalImg(this)" id="top" cx="800" cy="650" r="80" stroke="green" filter="url(#this_image)" class="counter2 elsalvador oculto"></circle>

           <text x="620" y="850" font-family="Inter" font-size="55" class="textolsalvador" fill=" #3C3C3C">
                El Salvador
              </text>
          <text x="660" y="900" font-family="Inter" font-size="30" class="textolsalvador2" fill=" #3C3C3C">Founded 2009</text>

<!-------------------***********************------------------->
<!-----------------***********BELICE**********------------------>

               <filter id="this_image2" x="0%" y="0%" width="100%" height="100%">
                  <feImage xlink:href="assets/images/mapa/MAP-04.svg"></feImage>

                </filter>
            <circle onmouseover="bigImg(this)" onmouseout="normalImg(this)" id="top" cx="850" cy="200" r="90" stroke="green" filter="url(#this_image2)" class="belice oculto"></circle>

         <text x="1050" y="150" font-family="Inter" font-size="55" class=" textobelice" fill=" #3C3C3C">Belice</text>
         <text x="1030" y="200" font-family="Inter" font-size="30" class="textobelice2" fill=" #3C3C3C">Founded 2015</text>
<!-------------------***********************------------------->

<!-----------------***********GUATEMALA**********------------------>


              <filter id="this_image3" x="0%" y="0%" width="100%" height="100%">
                  <feImage xlink:href="assets/images/mapa/MAP-05.svg"></feImage>
              </filter>
            <circle onmouseover="bigImg(this)" onmouseout="normalImg(this)" id="top" cx="650" cy="360" r="170" stroke="green" filter="url(#this_image3)" class=" guatemala oculto" ></circle>

         <text x="100" y="680" font-family="Inter" font-size="55" class=" textoguatemala" fill=" #3C3C3C">Guatemala</text>
        <text x="140" y="740" font-family="Inter" font-size="30" class="textoguatemala2" fill=" #3C3C3C">Founded 2017</text>
<!-------------------***********************------------------->

<!-----------------***********HONDURA**********------------------>
            <!--<defs>
                <pattern id="image4" x="420" y="180" patternUnits="userSpaceOnUse" height="1100" width="1100">
                  <image x="-300" y="20" height="75" width="900" xlink:href="images/mapa/MAP-06.svg" id="unaimagen"></image>
                </pattern>
              </defs>-->
             <filter id="this_image4" x="0%" y="0%" width="100%" height="100%">
                  <feImage xlink:href="assets/images/mapa/MAP-06.svg"></feImage>
              </filter>
            <circle onmouseover="bigImg(this)" onmouseout="normalImg(this)" id="top" cx="1100" cy="500" r="250" stroke="green" filter="url(#this_image4)" class="hondura oculto"></circle>

            <text x="1500" y="250" font-family="Inter" font-size="55" class="textohondura" fill=" #3C3C3C">Honduras</text>
            <text x="1520" y="310" font-family="Inter" font-size="30" class="textohondura2" fill=" #3C3C3C">Founded 2017</text>
<!-------------------***********************------------------->





<!-----------------***********NICARAGUA**********------------------>
              <filter id="this_image8" x="0%" y="0%" width="100%" height="100%">
                <feImage xlink:href="assets/images/mapa/MAP-08.svg"></feImage>
            </filter>
          <circle onmouseover="bigImg(this)" onmouseout="normalImg(this)" id="top" cx="1220" cy="730" r="250" stroke="green" filter="url(#this_image8)" class="nicaragua oculto"></circle>

          <text x="1530" y="750" font-family="Inter" font-size="55" class="textohondura" fill=" #3C3C3C">Nicaragua</text>
          <text x="1550" y="800" font-family="Inter" font-size="30" class="textohondura2" fill=" #3C3C3C">Founded 2023</text>
<!-------------------***********************------------------->





<!-----------------***********COSTARICA**********------------------>

                <!--<pattern id="image5" x="600" y="520" patternUnits="userSpaceOnUse" height="1100" width="1100">
                  <image x="-300" y="20" height="75" width="900" xlink:href="images/mapa/MAP-07.svg" id="unaimagen"></image>
                </pattern>
              </defs>-->
              <filter id="this_image5" x="0%" y="0%" width="100%" height="100%">
                  <feImage xlink:href="assets/images/mapa/MAP-07.svg"></feImage>
              </filter>
            <circle onmouseover="bigImg(this)" onmouseout="normalImg(this)" id="top" cx="1440" cy="1095" r="100" stroke="green" filter="url(#this_image5)" class="costarica oculto"></circle>

             <text x="1000" y="1300" font-family="Inter" font-size="55" class="textocosta" fill=" #3C3C3C">Costa Rica</text>
            <text x="1045" y="1360" font-family="Inter" font-size="30" class="textocosta2" fill=" #3C3C3C">Founded 2019</text>
<!-------------------***********************------------------->

<!-----------------***********PANAMA**********------------------>
            <!--<defs>
                <pattern id="image6" x="850" y="620" patternUnits="userSpaceOnUse" height="1100" width="1100">
                  <image x="-300" y="20" height="75" width="900" xlink:href="images/mapa/MAP-09.svg" id="unaimagen"></image>
                </pattern>
              </defs>-->
              <filter id="this_image6" x="0%" y="0%" width="100%" height="100%">
                  <feImage xlink:href="assets/images/mapa/MAP-09.svg"></feImage>
              </filter>
            <circle onmouseover="bigImg(this)" onmouseout="normalImg(this)" id="top" cx="1880" cy="1300" r="200" stroke="green" filter="url(#this_image6)" class="panama oculto"></circle>

           <text x="1880" y="1050" font-family="Inter" font-size="55" class="textopanama" fill=" #3C3C3C">Panama</text>
          <text x="1885" y="1120" font-family="Inter" font-size="30" class="textopanama2" fill=" #3C3C3C">Founded 2016</text>
<!-------------------***********************------------------->
       </svg>
</div>




