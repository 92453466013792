import { Component, OnInit  } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'mardiinDise';

  inicio(){
    window.scrollTo(0, 0);
  }
  constructor() { }
  activeClass=false;

  ngOnInit() {
  }
  toggleClass(){
    this.activeClass=!this.activeClass
  }


}
