<!-- Header -->
<header id="header" class="header header2">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="texto3">
          <h1 class="h1-large texto1"><br><br><br></h1>
          <h1 class="h1-large">Our Waters <br>& Juices </h1>
          <p data-animation="fadeInLeft" data-delay=".6s" class="text">
            We focus on giving life to brands, in order to distribute the best quality products.
          </p>
        </div>
      </div> <!-- end of col -->
      <div class="col-lg-6">
        <div class="button-container">

        </div> <!-- end of button-container -->
      </div> <!-- end of col -->
    </div> <!-- end of row -->
  </div> <!-- end of container -->
</header> <!-- end of header -->
<!-- end of header -->



<!-- Header -->
<div id="header" class="header header3">
  <div class="container ">
    <div class="row">
      <div class="carousel" id="test3">

        <input type="radio" name="item3" value="1" checked>
        <div class="col-md-12" style="position:relative;">

          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0095F4">
                  Polar
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Polar </tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Polar was born in the city of Worcester, Massachusetts. One of their main products is the
                    Traditional Tonic Water & More One of the classic staples of any bar. Crafted with quinine naturally
                    sourced from the bark of the cinchona tree the bitterness enhances quality vodka and botanical gins.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image2434" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1050" width="1350" xlink:href="assets/images/Productos/newb/nb70.png"
                      id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image2434)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->

        </div>








      </div>

    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of header -->


<!-- Header -->
<div id="" class=" header3">
  <div class="container ">
    <div class="row">
      <div class="carousel" id="test4">
        <input type="radio" name="item4" value="1" checked>
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#00609B">
                  Waters
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Acqua Panna</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    The unique flavor profile and mineral composition of Acqua Panna comes from its 14-year journey
                    through the aquifer beneath the sundrenched hills of Tuscany, obtaining the unmistakable tasting
                    water. There is a reason why the greatest chefs and sommeliers choose Acqua Panna.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image32" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1020" width="1350" xlink:href="assets/images/6/Bottles/b1.png"
                      id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image32)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item4" value="2">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#00609B">
                  Waters
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">S.Pellegrino Sparkling Mineral Water</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    S.Pellegrino is a fine quality mineral water that flows from a natural spring in the San Pellegrino
                    Terme area in Val Brembana, nestled in the foothills of the Italian Alps. S.Pellegrino surfaces
                    naturally enriched with mineral salts.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image33" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/SPellegrino.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image33)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item4" value="3">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#00609B">
                  Waters
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">SanPellegrino Italian Sparkling Drinks</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    From traditional sparkling fruit drinks to low-calorie options, tasteful mixers and organic fruit
                    tea, discover a world of fabulous flavours. These classic favourites are created with 100%
                    Mediterranean fruits, carefully selected and crafted for your enjoyment.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image34" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/Sanpellegrino.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image34)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>



        <input type="radio" name="item4" value="4">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#00609B">
                  Waters
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">MIXERS SAN PELLEGRINO TONICA</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Sanpellegrino Tonica Oakwood is a dry and intense tonic water with specially selected oak extract.
                    The oak extract gives it a totally original flavor, which you will never find in another tonic, with
                    pleasant first-hand bitter notes. Sanpellegrino Tonica Citrus is a scented tonic with hints of
                    orange peel and lemon. Sanpellegrino Ginger Beer is an elegant ginger beer with delicate hints of
                    spice.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image34" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/Sanpellegrino.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image34)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>


        <input type="radio" name="item4" value="4">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#00609B">
                  Waters
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">SanPellegrino Italian Sparkling Drinks</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    From traditional sparkling fruit drinks to low-calorie options, tasteful mixers and organic fruit
                    tea, discover a world of fabulous flavours. These classic favourites are created with 100%
                    Mediterranean fruits, carefully selected and crafted for your enjoyment.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image34" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/Sanpellegrino.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image34)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>



        <input type="radio" name="item4" value="5">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#00609B">
                  Waters
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">SAN PELLEGRINO ESSENZA FLAUVORED</tspan>
                </text>
                <text x="400" y="380" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">SPARKLING MINERAL WATER</tspan>
                </text>
                <foreignObject x="400" y="580" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Discover a tasty new way to enhance your experience with a touch of flavor. The same soft bubbles of
                    S.Pellegrino mixed with a vibrant blend of Mediterranean fruit flavors.Natural flavors. Zero
                    calories. No artificial ingredients. Pair it with medium-flavored dishes, such as sautéed
                    vegetables, white meat, or freshly made pasta.

                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image351" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/SANPELLEGRINOESSENZA.jpg" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image351)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item4" value="4">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#00609B">
                  Waters
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">MAR DE FRADES ALBARIÑO</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Mar de Frades Albariño is a wine that envelops you with its freshness and body, creating a balance between delicate elegance and attractive volume. The nose offers delicious aromas reminiscent of tropical fruits (mango, passion fruit) accompanied by floral and balsamic notes. Its passage through the mouth is refreshing and tasty. A white wine that flows with all the expression of the Albariño variety, accompanied by an exquisite saline and mineral character.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="f1" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/MAR DE FRADES ALBARIÑO.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#f1)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

      </div>


    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of header -->



<!-- Header -->
<div id="" class=" header3">
  <div class="container ">
    <div class="row">

      <div class="carousel" id="test5">

        <input type="radio" name="item5" value="1" checked>
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0097F4">
                  Mixers
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Perrier</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Elegant, sparkling and refreshing. Perrier Carbonated Mineral Water has delighted generations of
                    beverage seekers for over 150 years, with its unique blend of distinctive bubbles and balanced
                    mineral content.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image35" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/Perrier.png"
                      id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image35)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item5" value="1" checked>
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0097F4">
                  Mixers
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">MAISON PERRIER</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Bubbles combine with delicious natural flavors in Perrier mineral waters. With just a touch of
                    flavor, you can quench your thirst or cleanse your palate, with zero calories and zero sweeteners.
                    Born in the south of France, Perrier carbonated mineral water has delighted people around the world
                    for more than 150 years. It is a delicious alternative to soft drinks and a sophisticated option to
                    satisfy your thirst.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image46" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/MAISON_PERRIER.png"
                      id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image46)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>


      </div>


    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of header -->


<!-- Header JUGOS -->
<div id="header" class="header header3">
  <div class="container ">
    <div class="row">

      <div class="carousel" id="test6">


        <input type="radio" name="item6" value="1" checked>
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#003459">
                  Ocean Spray Cran Grape
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Ocean Spray Cran Grape</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    The juicy taste of grapes and crisp, clean taste of cranberries to make a good-for-you juice drink
                    that's fat-free, low in sodium, and a daily dose of vitamin C.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image36" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/OceanSprayCranGrape.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image36)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>


        <input type="radio" name="item6" value="2">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#003459">
                  <!--  Ocean Spray Cranberry -->Ocean Spray Cran Grape
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Ocean Spray Cranberry </tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Enjoy the crisp, clean taste of real cranberries straight from the bog. It’s a delicious way to get
                    your daily dose of vitamin C and unique cranberry health benefits. It’s good for you and your immune
                    system.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image37" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/OceanSprayCranberry.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image37)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>


        <input type="radio" name="item6" value="3">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#003459">
                  Ocean Spray Cran Grape
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Ocean Spray Light Cranberry </tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    It’s the crisp, clean taste of cranberries, without all the calories. Our Light Juice Drinks are
                    sweetened with sucralose so they have just 50 calories and 10 grams of sugar. Made with real juice
                    and all the cranberry flavor and goodness.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image38" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/OceanSprayCranberryLight.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image38)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item6" value="3">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#003459">
                  Ocean Spray Cran Grape
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">OCEANSPRAY RUBYRED </tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    It is made from 100% of the sweet and invigorating pink grapefruit flavor. It gives you your daily dose of vitamin C in every glass, and helps you strengthen your immune system. Plus, it's naturally fat-free, cholesterol-free, and low in sodium. in high fructose corn syrup; no artificial flavors or preservatives; no added sugars; good source of vitamin C.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image39" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/OCEANSPRAYRUBYRED.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image39)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item6" value="3">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#003459">
                  Ocean Spray Cran Grape
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">OCEANSPRAY CRAN UVA  </tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    It combines the juicy flavor of grapes with the freshness and purity of cranberries to create a juice drink that is good for you, fat-free and low in sodium.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image40" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/OCEAN SPRAY CRAN UVA 1L.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image40)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item6" value="3">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#003459">
                  Ocean Spray Cran Grape
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">OCEAN SPRAY CRAN RASPBERRY</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Ocean Spray Cran-Raspberry Juice Drink combines the delicious flavor of raspberries and the fresh, clean taste of blueberries to make a healthy, fat-free, low-sodium, daily dose juice drink of vitamin C.
                </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image41" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/OCEAN SPRAY CRAN RASPBERRY.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image41)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item6" value="3">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#003459">
                  Ocean Spray Cran Grape
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">OCEAN SPRAY WHITE CRANBERRY AND PEACH</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Ocean Spray White Cranberry & Peach Juice is a refreshing and delicious drink that combines the sweet flavor of peaches with the tartness of white cranberries. It is a great source of hydration and provides a boost of energy. Plus, it contains no artificial flavors or preservatives, making it a healthier option for your daily drink.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image42" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/OCEAN SPRAY WHITE CRAN PEACH.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image42)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item6" value="3">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#003459">
                  Ocean Spray Cran Grape
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">OCEAN SPRAY PINK CRANBERRY JUICE</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Ocean Spray introduces its light and refreshing Pink Cranberry Cocktail Juice drink, made with pink cranberries picked by the cooperative's more than 700 farmers. Pink Cranberry Juice Cocktail, with a hint of bold and tart Cranberry Juice Cocktail, is only 100 calories per serving, contains 100% vitamin C per serving, and has no artificial flavors or preservatives.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image43" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/OCEAN SPRAY PINK CRANBERRY.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image43)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>


        <input type="radio" name="item6" value="3">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#003459">
                  Ocean Spray Cran Grape
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">OCEAN SPRAY CRAN LEMONADE </tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Made with real fruit juice. Cranberry lemonade from concentrate, no artificial flavors or preservatives. Pasteurized. The balance of crisp, sun-ripened cranberries with fresh-tasting lemonade is a great summer thirst-quencher that's perfect any time of year. Serve over ice, blend into a smoothie, or freeze as a refreshing fruit pop! Contains 7% juice.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image44" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/OCEAN SPRAY CRAN LEMONADE.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image44)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item6" value="3">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#003459">
                  Ocean Spray Cran Grape
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">CRAISINS DRIED CRANBERRIES</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Surprise your taste buds with the sweet, delicious taste of Craisins® Dried Cranberries. Made with real fruit and good as a snack or in a recipe, they’re gluten free. Plus, they’re a good source of fiber and 1 serving of Craisins® Original Dried Cranberries meets 25% of your daily recommended fruit needs.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image45" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/OCEAN SPRAY CRAISINS DRIED CRANBERRIES.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image45)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

      </div>


    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of header -->
