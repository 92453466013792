<!-- Header -->
<header id="header" class="header header2">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="texto3">
                    <h1 class="h1-large texto1"><br><br><br></h1>
                    <h1 class="h1-large">Our Groceries <br> </h1>
                    <p data-animation="fadeInLeft" data-delay=".6s" class="text">
                        We focus on giving life to brands, in order to distribute the best quality products.
                    </p>
                </div>
            </div> <!-- end of col -->
            <div class="col-lg-6">
                <div class="button-container">
                   
                </div> <!-- end of button-container -->
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</header> <!-- end of header -->
<!-- end of header -->


<!-- Header -->
   <div id="header" class="header header3" >
    <div class="container "  >
          <div class="row" >

            <div class="carousel" id="test6">   
                <input type="radio" name="item6" value="1" checked>
                <div class="col-md-12" style="position:relative;" >
                    <!-- **contenido2 -->
                    <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#003459">
                                    Chiles
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Yucateco Caribbean Hot Sauce</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Full of hot Caribbean flavor with a touch of Mayan tradition. "It is made from a perfect blend of yellow Habanero chili peppers and carrots (a source of vitamin A and beta-carotene). It is ideal to accompany any type of dish, including breakfast. Try tropical ranch eggs served with this delicious spicy sauce.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image39" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1020" width="1350" xlink:href="assets/images/7/Bottles/b1.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image39)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                <!--  fin contenido -->
                </div>

             
            </div>


        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of header -->

