import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { SpiritComponent } from './spirit/spirit.component';
import { BeersComponent } from './beers/beers.component';
import { WinesComponent } from './wines/wines.component';
import { WaterComponent } from './water/water.component';
import { GroceriesComponent } from './groceries/groceries.component';
 import {IvyCarouselModule} from 'angular-responsive-carousel';
 import {SlideshowModule} from 'ng-simple-slideshow';
import { ContactoComponent } from './contacto/contacto.component';


@NgModule({
  declarations: [
    HomeComponent,
    AppComponent,
    NosotrosComponent,
    SpiritComponent,
    BeersComponent,
    WinesComponent,
    WaterComponent,
    GroceriesComponent,
    ContactoComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IvyCarouselModule,
    SlideshowModule,
    RouterModule.forRoot([ 
        { path: '',   redirectTo: '/home', pathMatch: 'full' },
        { path: 'home', component: HomeComponent },
        { path: 'nosotros', component: NosotrosComponent },
        { path: 'spirit', component: SpiritComponent },
        { path: 'beers', component: BeersComponent },
        { path: 'wines', component: WinesComponent },
        { path: 'water', component: WaterComponent },
        { path: 'groceries', component: GroceriesComponent },
        { path: 'contacto', component: ContactoComponent }
       ], { useHash: true })


  ],
  exports: [RouterModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
