<!-- Header -->
<header id="header" class="header header2">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="texto3">
          <h1 class="h1-large texto1"><br><br><br></h1>
          <h1 class="h1-large">Our Wines <br> </h1>
          <p data-animation="fadeInLeft" data-delay=".6s" class="text">
            We focus on giving life to brands, in order to distribute the best quality products.
          </p>
        </div>
      </div> <!-- end of col -->
      <div class="col-lg-6">
        <div class="button-container">

        </div> <!-- end of button-container -->
      </div> <!-- end of col -->
    </div> <!-- end of row -->
  </div> <!-- end of container -->
</header> <!-- end of header -->
<!-- end of header -->






<!--

<div class="carousel" id="test2">
    <input type="radio" name="item2" value="1" checked>
    <div>

    </div>

    <input type="radio" name="item2" value="2">
    <div>
       <h1>HOLA5</h1>
    </div>

</div>


-->



<!-- Header -->
<div id="header" class="header header3">
  <div class="container ">
    <div class="row">
      <div class="carousel" id="test3">

        <input type="radio" name="item3" value="1" checked>
        <div class="col-md-12" style="position:relative;">

          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0095F4">
                  WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Vinos Aromo </tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Vinos Aromo offers a wide range of wines with character and unique attributes that reflect the
                    essence of the Maule Valley in Chile. Aroma Barrel Selection The Blend is a revelation that is born
                    from learning to listen to nature, what the earth and the sun tell us, It has 13% vol. of Alcohol
                    and the previously selected grapes are harvested by hand.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image2433" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/newb/nb6.png"
                      id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image2433)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->

        </div>

        <input type="radio" name="item3" value="2">
        <div class="col-md-12" style="position:relative;">

          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0095F4">
                  WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Aromo Viogner</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Aromo Viognier is a full-bodied white with a rich background of savory notes, a very aromatic
                    peach, apricot and may blossom fruit character, but without any sweetness. No oak and fresh fruit
                    makes this wine a real winner. The Aromo wines have a great charm and appeal and this is no
                    exception.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image24332" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/AromoViognier.svg" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image24332)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->

        </div>

        <input type="radio" name="item3" value="3">
        <div class="col-md-12" style="position:relative;">

          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0095F4">
                  WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Aromo Reserva Privada Syrah</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    With the wide mouthfeel you will experience a juicy attack with firm tannins from Aromo Reserve
                    Privada Syrah. On the nose, you'll smell aromas of intense ripe black fruits and some hints of white
                    pepper and black currant.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image243321" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/AromoReservaSyra.svg" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image243321)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->

        </div>

        <input type="radio" name="item3" value="4">
        <div class="col-md-12" style="position:relative;">

          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0095F4">
                  WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Dogma Sauvignon Blanc</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Dogma Sauvignon Blanc has a refined and delicate taste, the wine has a bright stray yellow color,
                    that take attention because of his intensity. When you smell the wine, you can sense a
                    harmonious mix of citric and tropical aroma that makes you remember passion fruit, grapefruit
                    and lime, in addition to herbaceous notes that add a special touch to the bouquet. In the mouth,
                    this wine presents a vibrant and balanced acidity, which provides a refreshing and pleasant
                    sensation, as well as a creamy and persistent texture, which highlights intense flavors of citrus
                    and
                    tropical fruits, followed by a delicate and long aftertaste.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="w1" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/DogmaSauvignonBlanc.svg" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#w1)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->

        </div>

        <input type="radio" name="item3" value="5">
        <div class="col-md-12" style="position:relative;">

          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0095F4">
                  WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Dogma Cabernet Sauvignon</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Dogma, Special Reserve, blend, 60% Caberner Sauvignon 40% Syrah, Valle delñ Maule. Dogma
                    delivers a perfect balance and harmony between the rich fruit notes of the finest varietal grapes
                    and the balanced maturation in the noble oak of French and American barrels.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="w2" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/DogmaCabernetSauvignon.svg" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#w2)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->

        </div>


        <input type="radio" name="item3" value="6">
        <div class="col-md-12" style="position:relative;">

          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="950" rx="20" ry="20" fill="#0095F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0095F4">
                  WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Caleuche Reserve Malbec</tspan>
                </text>
                <foreignObject x="400" y="450" width="53%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Caleuche Reserva Malbec 2019 is made 100% with Malbec from the Maule Valley in Chile. Located
                    between the Andes and the Chilean coast, the Maule Valley benefits from a significant variation in
                    temperature between day and night, the rainy season is mainly limited to winter, so summer is hot
                    and dry. These conditions allow us to obtain healthy grapes, with high concentrations of color,
                    flavor and aroma. The wine has an intense and bright violet red color. On the nose, light and ripe
                    fruit with cherries, blueberries, strawberries, violets and a light touch of gunpowder typical of
                    the
                    Malbec grape in South America. It is a fresh, concentrated and easy-drinking wine, with sweet and
                    well-structured tannins. This Malbec is designed to be drunk young. Mocha, blackberry, coffee,
                    and some of that gunpowder smoke wafts through the aftertaste.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="w3" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/CaleucheMalbec.svg" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#w3)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->

        </div>

        <input type="radio" name="item3" value="7">
        <div class="col-md-12" style="position:relative;">

          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0095F4">
                  WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Caleuche Carmenere</tspan>
                </text>
                <foreignObject x="400" y="450" width="54%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Caleuche Reserva Carménère 2020 is made 100% with Carménère from the Maule Valley in Chile.
                    Located between the Andes and the Chilean coast, the Maule Valley benefits from a significant
                    variation in temperature between day and night, the rainy season is mainly limited to winter, so
                    summer is hot and dry. These conditions allow us to obtain healthy grapes with high
                    concentrations of color, flavor and aroma. The wine has a light ruby ​​red color with violet
                    reflections. On the nose we find the typical aromas of the Carménère grape: raspberry, plum,
                    pepper and coffee accompanied by ripe black fruits and spices. A medium-bodied, delicate and
                    fruity wine, with good structure and ample tannins that leave a sweet and pleasant sensation.
                    Finally, something chocolatey and slightly smoky lingers on the palate.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="w4" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/CaleucheCarmenere.svg" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#w4)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->

        </div>


        <input type="radio" name="item3" value="7">
        <div class="col-md-12" style="position:relative;">

          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0095F4">
                  WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">RAMON BILBAO RESERVA</tspan>
                </text>
                <foreignObject x="400" y="450" width="54%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Bilbao Reserva is ruby ​​red. Medium-high intensity, with the presence of tears, clean and bright. Frank and complex. With nuances of red fruit, from the fresh vineyards of Rioja Alta. Balsamic and spicy aromas, all of them in perfect balance with the aromas of leather, tobacco leaf and tea after aging in the bottle. It is long, with fresh acidity, creamy and pleasant tannins. In short, a balanced wine, in harmony and ready to enjoy.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="w5" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/PRUEBA 2.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#w5)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->

        </div>


        <input type="radio" name="item3" value="7">
        <div class="col-md-12" style="position:relative;">

          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0095F4">
                  WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">RAMON BILBAO CRIANZA</tspan>
                </text>
                <foreignObject x="400" y="450" width="54%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Cruz de Alba Crianza is a single-varietal Tempranillo. The vineyards are located in Pago de los Hoyales, near Peñafiel, planted on terraces with a limestone surface. The production of Cruz de Alba Crianza begins with a harvest and manual selection of the grapes. Once in the winery, the must undergoes traditional maceration and fermentation. It is powerful and tasty. Well structured, full-bodied, with an enveloping step. Well integrated tannins and good acidity. Fruity with toasted memories. Dark and intense cherry cherry; medium-high intensity. Aromas of ripe black fruit (blackberries) with toasted and coffee notes. Balsamic and herbaceous background.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="w6" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/RAMON BILBAO CRIANZA.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#w6)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->

        </div>

        <input type="radio" name="item3" value="7">
        <div class="col-md-12" style="position:relative;">

          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0095F4">
                  WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">CUATRO RAYAS TEMPRANILLO</tspan>
                </text>
                <foreignObject x="400" y="450" width="54%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Wine from the Land of Castilla y León. Tempranillo from our own organically produced vineyards; aged for 3 months in barrels. Medium layer ruby ​​red color. clean and bright with purple reflections; intense aroma with hints of ripe forest fruits; In the mouth it is soft and velvety, with a good balance between alcohol and acidity, without edges, round and long aftertaste.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="w7" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/CUATRO RAYAS PECATIS TUIS TEMORANILLO.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#w7)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->

        </div>

        <input type="radio" name="item3" value="7">
        <div class="col-md-12" style="position:relative;">

          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0095F4">
                  WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">SANTA MARGHERITA CHIANTI CLASSICO</tspan>
                </text>
                <foreignObject x="400" y="450" width="54%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Santa Margherita Chianti Classico is a red, a great wine made in one of the most famous places in Italy. Specifically, Chianti is one of the oldest regions, where vineyards grow on rocky and clay soils (specifically shale and sandstone marls), between valleys and slopes. Chianti Claasico with the aromas of this complex red wine range from cherries and plums to gladiolus flowers and earthy flint. The flavors tannins from the oak aging are bright and round, with a dry finish, warm and earthy.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="w8" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/SANTA MARGHERITA CHIANTI CLASSICO.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#w8)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->

        </div>

        <input type="radio" name="item3" value="7">
        <div class="col-md-12" style="position:relative;">

          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0095F4">
                  WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">SANTA MARGHETITA MERLOT</tspan>
                </text>
                <foreignObject x="400" y="450" width="54%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">

In Northern Italy, Veneto is renowned for its red wines from the Merlot grape. The climate there is ideal for making wines with aromas of red fruit and enveloping sensations in the mouth. The wine has a bright ruby ​​red color, which is almost purple when young. Nose: Its aroma is persistent, fine and elegant, with notes of ripe cherries and plums on a background of vanilla and violets. Mouth: On the palate, it is dry, well balanced and velvety, with well integrated tannins. Pairing: Recommended with starters with rich flavors and pasta dishes with salty sauces. It is also a good combination for roasted red meats and aged cheeses.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="w9" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/SANTA MARGHERITA MERLOT.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#w9)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->

        </div>


        <input type="radio" name="item3" value="7">
        <div class="col-md-12" style="position:relative;">

          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0095F4">
                  WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">AVGVSTVS CABERNET FRANC</tspan>
                </text>
                <foreignObject x="400" y="450" width="54%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    With a beautiful and intense garnet color with violet tones, its extensive floral aromas, such as rose, lily and violet, along with some vegetal notes (very common in this variety) and very ripe red fruits merge with an elegant, highly integrated wood that It gives it a very special character. With a great structure and character, but above all with great finesse, sweetness and elegance. A curious and different wine, for those who want to know new things.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="w10" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/AVGVSTVS CABERNET FRANC.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#w10)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->

        </div>

        <input type="radio" name="item3" value="7">
        <div class="col-md-12" style="position:relative;">

          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0095F4">
                  WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">AVGVSTVS CABERNET SAUVUGNON MERLOT</tspan>
                </text>
                <foreignObject x="400" y="450" width="54%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    An extreme quality wine that mixes a powerful and tasty sweet tannin, with a structured, sweet and very meaty body. Our Avgvstvs Cabernet Sauvignon-Merlot is a complex wine, full of nuances of red fruits and spicy notes, but at the same time, it is a very easy to drink wine. Wine of extreme quality that mixes a powerful and tasty sweet tannin, with a structured, sweet and very meaty body. Versatile and ductile, this Avgvstvs can be enjoyed practically from the moment it is bottled, gaining elegance and finesse as time passes. Pairs with red meats, roasts, game and dry cheese.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="w11" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/AVGVSTVS CABERNET SAUVIGNON MERLOT.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#w11)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->

        </div>

        <input type="radio" name="item3" value="7">
        <div class="col-md-12" style="position:relative;">

          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0095F4">
                  WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">TERRELIADE NERO D'AVOLA SYRAH</tspan>
                </text>
                <foreignObject x="400" y="450" width="54%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Deep ruby ​​red in color, it has aromas of still fresh red fruit and a light but pleasant spiciness. In the mouth it is fine and broad with an agile structure and a nice freshness. Red winemaking involves maceration for approximately two weeks. After malolactic fermentation, the wine is left to refine in steel to favor the varietal aspect that, once in the bottle, harmonizes and blends, providing elegance and pleasantness.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="w12" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/TERRELIADE NERO DAVOLA.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#w12)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->

        </div>


        <input type="radio" name="item3" value="7">
        <div class="col-md-12" style="position:relative;">

          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0095F4">
                  WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">AVGVSTVS ULL DE LLEBRE </tspan>
                </text>
                <foreignObject x="400" y="450" width="54%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Over Santes Creus town we find sloped and reddish lands. Here the vineyard, calmed, enjoys the most continental warmth of Penedés. Elaborated with delicacy, we obtain a wine with subtle and expressive style. In nose it reminds us like red fruits with a light toasted background. In the mouth, however, we find a lot of power, but at the same time the fluidity and delicacy of this grape variety when it has been made with this style.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="w13" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/AVGVSTVS ULL DE LLEBRE.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#w13)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->

        </div>

      </div>

    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of header -->



<!-- Header -->
<div id="" class=" header3">
  <div class="container ">
    <div class="row">
      <div class="carousel" id="test4">

        <input type="radio" name="item4" value="1" checked>
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#00609B">
                  White Wines
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Santa Margherita Pinot Grigio</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    The ande of Sema Mongherta wines and our signature varietal. This renowned beauty is full of flavor
                    and makes a delightful addition to any ocasion, ir pairs exceptionally well with seafood and saft,
                    fresh cheeses like mozarella.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image26" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/SantaMargheritaPinotGrigio.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image26)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item4" value="1" checked>
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#00609B">
                  White Wines
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">RAMON BILBAO VERDEJO</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Wine of greenish yellow color, medium intensity, clean, bright and with the presence of tears.
                    Intense on the nose with aromas of jasmine, white fruit, anise, citrus and notes of bay leaf, fennel
                    and dried grass. In the mouth it is fresh, with the presence of acidity and length, in perfect
                    alcohol balance. Citrus and floral appear again in the retronasal. Perfect as an aperitif and
                    accompanying cold dishes such as creams, steamed vegetables, salads, creamy cheeses and white meats.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image27" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/RAMON BILBAO VERDEJO.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image27)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item4" value="1" checked>
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#00609B">
                  White Wines
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">CUATRO RAYAS VERDEJO</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    This dry white Cuatro Rayas Verdejo Pecatis Tuis from Agrícola Castellana is made from a careful selection of the native Verdejo grape variety. The grapes are harvested when the grapes are perfectly ripe. Pale steely yellow color; Fresh and floral aromas predominate on the nose; soft palate and long aftertaste. Pairing: fresh fish and seafood.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image281" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/CUATRO RAYAS PECATIS TUIS VERDEJO.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image281)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

      </div>


    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of header -->



<!-- Header -->
<div id="header" class="header header3">
  <div class="container ">
    <div class="row">

      <div class="carousel" id="test5">

        <input type="radio" name="item5" value="1" checked>
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0097F4">
                  Sparkling Wines
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Sparkling Rosé</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Delightful floral aromas fill the air when you pop the cork on this pale pink sparkler. From a
                    brunch to a dinner party, it adds a delicious element to any gathering. Keep a bottle on hand for
                    spicy food too.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image271" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/SantaMargheritaSparklingRose.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image271)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item5" value="2">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0097F4">
                  Sparkling Wines
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Prosecco Superiore</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Created from luscious, aromatic Glera grapes, this bubbly is a celebration in a bottle and a
                    benchmark of quality. Crisp, clean, sweet, and lively, it pairs with everything from a rich
                    carbonara to a rosemary beef filet.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image28" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/SantaMargheritaProseccoSuperiore.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image28)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item5" value="3">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0097F4">
                  Sparkling Wines
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Torresella Pinot Grigio</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    The grapes are vinified as a white wine, thanks to a soft pressing carried out immediately after
                    their arrival at the winery. Excellent as an aperitif, it also goes well with quite elaborate fish
                    dishes.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image29" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/TorresellaPinotGrigio.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image29)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item5" value="4">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0097F4">
                  Sparkling Wines
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Torresella Prosecco</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    The base wine is put into pressurized tanks for the prise de mousse; here it ferments for 18 - 20
                    days, with the aid of selected yeasts, at a controlled temperature of between 15 and 18 °C (59 – 64
                    °F). A delicious aperitif, it is an ideal accompaniment for tasty (and even quite spicy) tidbits.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image30" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/TorresellaProsecco.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image30)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item5" value="5">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0097F4">
                  Sparkling Wines
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Torresella Chardonnay</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    After soft pressing, the must is fermented for around 12 days at 16 - 18 °C. Following the alcoholic
                    fermentation, the wine remains on its lees until it is time for the prise de mousse. The second
                    fermentation takes place in temperature-controlled pressurized tanks, inoculating the wine with
                    selected yeasts.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image31" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/TorresellaChardonay.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image31)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item5" value="6">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0097F4">
                  Sparkling Wines
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Luc Belaire Rosé</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Belaire Rosé epitomizes the qualities that have made the wines in our range so popular. Rosé is an
                    extraordinary wine from grape to glass, tailor-made to please crowds, anytime of year.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image8n" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/productosfinal/LucBelaireRose.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image8n)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item5" value="7">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0097F4">
                  Sparkling Wines
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Luc Belaire Fantome</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    it is a rosé champagne that stands out for its characteristics reminiscent of red fruits and this
                    wine takes these characteristics to another level, presenting its dry touch and floral aroma. As a
                    rather impressive detail you have that the bottle is able to light up in the dark and that can mean
                    a point of distinction for your events.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image9n" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/productosfinal/LucBelaireFantome.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image9n)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item5" value="8">
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0097F4">
                  Sparkling Wines
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">Luc Belaire Luxe</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Luc Belaire Rare Luxe is a 100% Chardonnay sparkling wine from Burgundy. A refreshing bottle of fizz
                    with notes of peach, apricot, grapefruit and toast, this is an excellent appetizer.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image10n" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/productosfinal/LucBelaireLuxe.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image10n)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>



      </div>


    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of header -->





<div id="" class=" header3">
  <div class="container ">
    <div class="row">
      <div class="carousel" id="test6">
        <input type="radio" name="item6" value="1" checked>
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#00609B">
                  Sangria
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">LOLEA 1</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    A delicious frizzante sangria made with red wine from Tempranillo and Cabernet Sauvignon grapes,
                    Mediterranean citrus fruits and a touch of cinnamon. A Cherry Red color; pleasant aromas, with notes
                    of cherry, orange peel, lemon candy, cinnamon and sour strawberry and low alcohol content (7% VOL
                    ALC) </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image300" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1600" y="0" height="1150" width="650" xlink:href="assets/images/Productos/Lolea1.png"
                      id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image300)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>






        <input type="radio" name="item6" value="2" checked>
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#00609B" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#00609B">
                  Sangria
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">LOLEA 2</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    A different sangria, with a frizzante touch, made with white wine from Macabeo and Airén grapes,
                    Mediterranean citrus fruits and a touch of vanilla. Clear, clean, with slight effervescence of fine
                    and constant bubbles; with notes of vanilla, jasmine, apricot; a balance between acidity and
                    sweetness and low alcohol content (7% VOL ALC)
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="image301" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1600" y="0" height="1150" width="650" xlink:href="assets/images/Productos/LOLEA2.png"
                      id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#image301)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>



      </div>


    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of header -->
<!-- end of header -->


<!-- Header VINOS ROSADOS -->
<div id="header" class="header3">
  <div class="container ">
    <div class="row">

      <div class="carousel" id="test5">

        <input type="radio" name="item5" value="1" checked>
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0097F4">
                  ROSÉ WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">RAMON BILBAO ROSADO</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Ramón Bilbao Rosado is a rosé wine from the D.O. Rioja made by Bodegas Ramon Bilbao. Ramón Bilbao Rosado 2022 is a wine that on the nose offers pleasant aromas of citrus (orange peel) and red fruits (strawberries, cherries) accompanied by herbal notes. In the mouth it flows light and refreshing, with vibrant acidity. A very pleasant rosé wine, balanced and easy to drink.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="S1" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/RAMON BILBAO ROSADO.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#S1)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

        <input type="radio" name="item5" value="1" checked>
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0097F4">
                  ROSÉ WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">SANTA MARGHERITA STILROSE</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    The delicate pink color introduces the floral fragrances of rose hips and the fruity notes of orange and wild strawberries with touches of lychees and ginger in an elegant and varied olfactory expression. The softness of the perfumes gradually manifests itself in a gustatory freshness of tasty succulence, which pulses with a sapidity that drives the crisp aromatic notes of berries and sweet spices.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="S2" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/SANTA MARGHERITA STILROSE.png" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#S2)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>


        <input type="radio" name="item5" value="1" checked>
        <div class="col-md-12" style="position:relative;">
          <!-- **contenido2 -->
          <div class="row">
            <div class="counter   col-md-12 fondodiv" id="">
              <svg viewBox="0 0 2580 1000" id="" class="">
                <!-- ***********************texto******************* -->
                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0097F4" />
                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class=""
                  fill="#0097F4">
                  ROSÉ WINES
                </text>
                <text x="400" y="300" font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0"
                  fill=" #fff">
                  <tspan x="400" dy="90">MINUTY COTES DE PROVENCE ROSE</tspan>
                </text>
                <foreignObject x="400" y="450" width="40%" font-family="Inter, sans-serif" height="100%"
                  style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                  <xhtml:span class="copy-popover" style="">
                    Château Minuty M de Minuty Côtes de Provence Rosé is a delightful French wine that is perfect for any occasion. The wine has a beautiful pale pink color and a nose that is full of fragrant roses, white peach, and and citrus fruits. On the palate, you will find a crisp, refreshing taste that is light and airy, with hints of red berries, peach, and apricot. The wine's acidity is perfectly balanced, making it an ideal wine to drink on its own or paired with a variety of dishes, including seafood, salads, and grilled meats. Overall, Château Minuty M de Minuty Côtes de Provence Rosé is a fantastic wine that will impress any wine lover.
                  </xhtml:span>
                </foreignObject>
                <!-- ********************************************* -->
                <!-----------------************imagen*********------------------>
                <defs>
                  <pattern id="S3" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000"
                    class="fondo1 ">
                    <image x="1300" y="0" height="1150" width="1350"
                      xlink:href="assets/images/Productos/M MINUTY COTES DE PROVENCE ROSE.webp" id="color"></image>
                  </pattern>
                </defs>
                <rect width="2550" height="1550" fill="url(#S3)" class="fondo1"></rect>
                <!-------------------***********************------------------->
              </svg>
            </div>
          </div>
          <!--  fin contenido -->
        </div>

      </div>


    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of header -->
