<!-- Header -->
<header id="header" class="header header2">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="texto3">
                    <h1 class="h1-large texto1"><br><br><br></h1>
                    <h1 class="h1-large">Our Beers <br> </h1>
                    <p data-animation="fadeInLeft" data-delay=".6s" class="text">
                        We focus on giving life to brands, in order to distribute the best quality products.
                    </p>
                </div>
            </div> <!-- end of col -->
            <div class="col-lg-6">
                <div class="button-container">

                </div> <!-- end of button-container -->
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</header> <!-- end of header -->
<!-- end of header -->








<!-- Header -->
   <div id="" class=" header3" >
    <div class="container "  >
          <div class="row" >
            <div class="carousel" id="test1">

                <input type="radio" name="item1" value="1" checked>
                <div class="col-md-12" style="position:relative;" >
                    <!-- **contenido2 -->
                    <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#003459">
                                    Ale
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Delirium Tremens</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        The Delirium Tremens was born in 1988 at the request of an Italian client. This beer is an outsider.Its white bottle is reminiscent of Cologne pottery wore. Delirium Tremens is a heavy blond beer of the triple type and its re-ferments in the bottle. Interestingly, three different types of yeast are used in its production.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image21" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/DeliriumTremens.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image21)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->
                </div>


                 <input type="radio" name="item1" value="2">
                <div class="col-md-12" style="position:relative;" >
                    <!-- **contenido2 -->
                    <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#003459">
                                    Ale
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Delirium Nocturnum</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Delirium Nocturnum is a triple fermented beer with re-fermentation in the bottle. At the beginning, a very pleasant mouthfeel of alcohol and smoothness. It is followed by increasing bitterness, which comes in part from hops, but also from roasted malt and chocolate malt.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image22" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/DeliriumTremensNocturnum.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image22)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->
                </div>

                 <input type="radio" name="item1" value="3">
                <div class="col-md-12" style="position:relative;" >
                    <!-- **contenido2 -->
                    <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#003459"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#003459">
                                    Ale
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Delirium Red</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Is a dark-red cherry beer made by the heroes that gave the world Delirium Tremens. Based on their blonde ale, it has a light pink, compact head, a soft aroma of almond and sour cherries, and tastes tangy and fruity, with a perfect balance between sweet and sour.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image23" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/DeliriumTremensRed.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image23)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->
                </div>




            </div>
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of header -->



 <!-- Header -->
   <div id="header" class="header header3" >
    <div class="container "  >
          <div class="row" >
            <div class="carousel" id="test2">

                <input type="radio" name="item2" value="1" checked>
                <div class="col-md-12" style="position:relative;" >

                     <!-- **contenido2 -->
                     <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#0095F4">
                                    Lager
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Grolsch</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Grolsch Premium Lager is a classic, weli-balanced European Pilsner crofted with three varieties of barley malts and a unique blend of hops. Brewed using a unique double fermentation process, it features a fresh, hoppy note, a mild citric fruity taste and a long, delicate finish.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image24" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/Grolsch.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image24)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->

                </div>

                <input type="radio" name="item2" value="2" >
                <div class="col-md-12" style="position:relative;" >
                      <!-- **contenido2 -->
                      <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#0095F4">
                                    Lager
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Peroni</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Peroni Nastro Azzurro is an Italian lager beer with a crisp and refreshing taste and subtle citrus aroma. Peroni beer has a big personality — bold, spirited, authentically Italian, and effortlessly stylish. This case of beer contains 5.1% ABV and is perfect for sharing refreshing drinks with friends during all seasons.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image25" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/Peroni.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image25)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->
                </div>





            </div>

        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of header -->



 <!-- Header -->
 <div id="header" class="header header3" >
    <div class="container "  >
          <div class="row" >
            <div class="carousel" id="test3">

                <input type="radio" name="item3" value="1" checked>
                <div class="col-md-12" style="position:relative;" >

                     <!-- **contenido2 -->
                     <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#0095F4">
                                  BEER
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Cerveceria 14</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Cervecería 14 is born in a new niche for all true beer lovers, a place to create, share and enjoy the best craft chelas in Latin America. Cervecería Catorce becomes an innovative force for the emerging culture of craft beer in Guatemala. Its star products are the "Chelas all 3 times" exotic beers, inspired by ingredients of local origin.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image240" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1500" y="0" height="1150" width="950" xlink:href="assets/images/Productos/newb/nb3.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image240)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->

                </div>


                <input type="radio" name="item3" value="2" checked>
                <div class="col-md-12" style="position:relative;" >

                     <!-- **contenido2 -->
                     <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#0095F4">
                                  BEER
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >Mahou</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                        Mahou beer born more than 40 years ago in Madrid to satisfy the most demanding palates. Its outstanding product is Mahou Cinco Estrellas, characterized by being a golden beer, with a creamy and consistent foam, It has 5.5% volume of alcohol with a characteristic, moderate and fine flavor with a fruity aroma.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image241" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1150" width="1350" xlink:href="assets/images/Productos/newb/nb4.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image241)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->

                </div>







                <input type="radio" name="item3" value="2" checked>
                <div class="col-md-12" style="position:relative;" >

                     <!-- **contenido2 -->
                     <div class="row">
                        <div  class="counter   col-md-12 fondodiv" id="">
                            <svg viewBox="0 0 2580 1000" id="" class="">
                        <!-- ***********************texto******************* -->
                                <rect x="300" y="250" width="2000" height="700" rx="20" ry="20" fill="#0095F4"/>
                                <text x="400" y="200" font-family="Inter, sans-serif" font-weight="600" font-size="55" class="" fill="#0095F4">
                                    BEER
                                </text>
                                <text x="400" y="300"  font-family="Inter, sans-serif" font-weight="600" font-size="55" dy="0" fill=" #fff">
                                    <tspan x="400" dy="90" >ASAHI BEER</tspan>
                                </text>
                                <foreignObject x="400" y="450" width="40%"  font-family="Inter, sans-serif" height="100%" style="text-align: justify; font: 400 1.1rem/1.625rem Inter, sans-serif;">
                                    <xhtml:span class="copy-popover" style="">
                                      It is a lager-type blonde beer that has captivated beverage consumers. It has a refreshing flavor with a light citrus touch, to contrast the concentration of malt. Light golden in color, medium body and a slight intensity in its malt aroma. It has a notable and short flavor with a touch of rice, which gives it a touch of dry palate. Excellent to accompany Japanese food.
                                    </xhtml:span>
                                </foreignObject>
                        <!-- ********************************************* -->
                        <!-----------------************imagen*********------------------>
                                <defs >
                                <pattern id="image242" x="" y="0" patternUnits="userSpaceOnUse" height="2000" width="3000" class="fondo1 ">
                                <image x="1300" y="0" height="1000" width="1350" xlink:href="assets/images/Productos/ASAHI.png" id="color"></image>
                                </pattern>
                            </defs>
                            <rect width="2550" height="1550" fill="url(#image242)" class="fondo1"></rect>
                        <!-------------------***********************------------------->
                            </svg>
                        </div>
                    </div>
                    <!--  fin contenido -->

                </div>





            </div>

        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of header -->



